import { useState } from "react";
import styled from "styled-components";
import GroupIcon from "@mui/icons-material/Group";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import demoImg from "../../assets/demoImg.svg";
import BackdropWrapper from "./BackdropWrapper";
import RecipeForm from "./RecipeForm";
import QuickAddRecipe from "./QuickAddRecipe";
import FavoriteRecipeHandler from "../recipes/FavoriteRecipeHandler";
import useGenerateImg from "../../hooks/useGenerateImg";
import { useEffect } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../authentication/firebase";
import { CircularProgress, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShareRecipe from "./ShareRecipe";

function RecipeDetails({ handleBackdrop, data, selectedDate, hideCloseIcon }) {
  const imageGeneration = useGenerateImg();
  // if (
  //   data.recipeType === "favorite" ||
  //   data.recipeType === "web" ||
  //   data.recipeType === "manual"
  // )
  // data = Object.assign({}, data);
  const [editBackdrop, setEditBackdrop] = useState(false);
  const [addBackdrop, setAddBackdrop] = useState(false);
  const [shareBackdrop, setShareBackdrop] = useState(false);
  const [recipeImg, setRecipeImg] = useState(data.image);

  const editRecipeHandler = (e, type) => {
    setEditBackdrop((prev) => !prev);
  };
  const addRecipeHandler = () => {
    setAddBackdrop((prev) => !prev);
  };
  const shareRecipeHandler = () => {
    setShareBackdrop((prev) => !prev);
  };

  if (!data.savedIngredients) {
    data.savedIngredients = data.extendedIngredients
      ? [...data.extendedIngredients]
      : [...data.ingredients];
  }

  if (!data.savedServings) {
    data.savedServings = data.servings;
  }

  const [servings, setServings] = useState(data.savedServings);
  const [ingredients, setIngredients] = useState(data.savedIngredients);

  let steps = [];
  data.steps !== undefined
    ? (steps = data.steps)
    : data.analyzedInstructions?.forEach((instruction) =>
        instruction.steps?.forEach((item) => steps.push(item.step))
      );

  function servingsChangeHandler(e) {
    const newValue = parseInt(e.target.value);
    if (newValue !== 0) {
      setServings(newValue);
      data.savedServings = newValue;

      setIngredients((prev) => {
        let newIngredientValues = prev.map((ingredient, index) => ({
          ...ingredient,
          amount:
            typeof ingredient.amount === "string"
              ? ingredient.amount
              : parseFloat(
                  (
                    ((data.extendedIngredients
                      ? data.extendedIngredients[index].amount
                      : data.ingredients[index].amount) /
                      data.servings) *
                    (newValue || 1)
                  ).toFixed(2)
                ),
        }));
        console.log("new ingredient values", newIngredientValues);
        data.savedIngredients = newIngredientValues;
        return newIngredientValues;
      });
    }
  }
  console.log("recipe details", data);
  useEffect(() => {
    async function renderImg() {
      if (!data.image && data.recipeType === "gpt") {
        try {
          console.log("prompts", data.title, data.ingredients, data.id);
          const url = await imageGeneration(data.title, data.ingredients);
          data.image = url;
          setRecipeImg(url);
          const docRef = doc(db, "gptRecipes", data.id);
          const res = await setDoc(docRef, data);
          console.log("res", res);
        } catch (e) {
          setRecipeImg(demoImg);
          console.log("recipe generate error, details page", e);
        }
      }
    }
    renderImg();
  }, []);

  return (
    <StyledDetails>
      <BackdropWrapper
        open={addBackdrop}
        handleBackdrop={addRecipeHandler}
        element={
          <QuickAddRecipe
            data={data}
            selectedServings={servings}
            handleBackdrop={addRecipeHandler}
            selectedDate={selectedDate}
          />
        }
      />
      <BackdropWrapper
        open={editBackdrop}
        handleBackdrop={editRecipeHandler}
        element={
          <RecipeForm
            data={data}
            recipeType={data.recipeType}
            handleBackdrop={editRecipeHandler}
          />
        }
      />
      <BackdropWrapper
        open={shareBackdrop}
        handleBackdrop={shareRecipeHandler}
        element={
          <ShareRecipe recipeData={data} handleBackdrop={shareRecipeHandler} />
        }
      />
      <div className={`heading ${!hideCloseIcon && "modal-active"}`}>
        <h4>{data?.title}</h4>
        {!hideCloseIcon && (
          <button className="icon" onClick={(e) => handleBackdrop(false)}>
            {<CloseIcon htmlColor="#F79C16" fontSize="large" />}
          </button>
        )}
      </div>
      <div className="content">
        <div className="main">
          <div className="img-div">
            {!recipeImg || recipeImg === "" ? (
              <CircularProgress
                style={{
                  color: "var(--primary-color)",
                  width: 20,
                  height: 20,
                }}
              />
            ) : (
              <div className="img">
                <img src={recipeImg} alt="plate" />
                <FavoriteRecipeHandler data={data} />
              </div>
            )}
            <div className="btns">
              {/* <LoadingButton
                loading={data.nutrition ? false : true}
                onClick={addRecipeHandler}
                title={"Add to Calendar"}
              /> */}
              <button onClick={addRecipeHandler}>Add to Calendar</button>
              <button
                className="secondary-btn"
                onClick={(e) => setShareBackdrop(true)}
              >
                Share
              </button>
              {(data.recipeType === "web" || data.recipeType === "manual") && (
                <button onClick={editRecipeHandler}>Edit</button>
              )}
            </div>
          </div>
          <div className="info">
            <h5 className="header">Details</h5>
            <div className="details">
              <div className="size">
                <GroupIcon fontSize="medium" htmlColor="#F79C16" />
                {/* <h5>Servings:</h5> */}
                <Tooltip placement="top" title="Change servings">
                  <input
                    type="number"
                    inputMode="decimal"
                    min={1}
                    max={25}
                    step={1}
                    value={servings}
                    onChange={servingsChangeHandler}
                  />
                </Tooltip>
              </div>
              <div className="time">
                <AvTimerIcon fontSize="medium" htmlColor="#F79C16" />
                <p>{data.readyInMinutes}min</p>
              </div>
              <div className="meal-type">
                <RestaurantIcon fontSize="medium" htmlColor="#F79C16" />
                <p>
                  {data?.dishType !== undefined
                    ? data.dishType
                    : data.dishTypes[0]}
                </p>
              </div>
              {data.healthScore && (
                <div className="health-score">
                  <MonitorHeartIcon fontSize="medium" htmlColor="#F79C16" />
                  <p>{parseInt(data.healthScore) / 10}/10</p>
                </div>
              )}
            </div>
            {data.nutrition?.nutrients && (
              <div className="details">
                <div>
                  <h5>Calories:</h5>
                  <p>
                    {data.nutrition.nutrients.map(
                      (nutrient) =>
                        nutrient.name === "Calories" &&
                        parseInt(nutrient.amount)
                    )}
                  </p>
                </div>
                <div>
                  <h5>Protien:</h5>
                  <p>
                    {data.nutrition.nutrients.map(
                      (nutrient) =>
                        nutrient.name === "Protein" && parseInt(nutrient.amount)
                    )}
                    g
                  </p>
                </div>
                <div>
                  <h5>Carbs:</h5>
                  <p>
                    {data.nutrition.nutrients.map(
                      (nutrient) =>
                        nutrient.name === "Carbohydrates" &&
                        parseInt(nutrient.amount)
                    )}
                    g
                  </p>
                </div>
                <div>
                  <h5>Fat:</h5>
                  <p>
                    {data.nutrition.nutrients.map(
                      (nutrient) =>
                        nutrient.name === "Fat" && parseInt(nutrient.amount)
                    )}
                    g
                  </p>
                </div>
              </div>
            )}
            <p>{data.summary}</p>
          </div>
        </div>
        <div className="mid main">
          <div className="ingredients">
            <h5>Ingredients</h5>
            <div>
              <ul>
                {ingredients.map((ingredient, index) => (
                  <li key={index}>
                    {ingredient.nameClean[0].toUpperCase() +
                      ingredient.nameClean.slice(1)}
                    , {ingredient.amount} {ingredient.unit}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="extra-info">
            {/* {data.carbonFootprint && (
              <div className="info-div">
                <h5>Carbon Footprint</h5>
                <p>{data.carbonFootprint}</p>
              </div>
            )}
            {data.foodWaste && (
              <div className="info-div">
                <h5>Food Waste</h5>
                {Object.entries(data.foodWaste).map(([key, value]) => (
                  <h5>
                    {key}: <span>{value}</span>
                  </h5>
                ))}
              </div>
            )} */}
            {data.healthBenefitsShort && (
              <div className="info-div">
                <h5>Health Benefits</h5>
                <div>
                  <ul>
                    {data.healthBenefitsShort.length > 0 ? (
                      data.healthBenefitsShort.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))
                    ) : (
                      <li>Not available</li>
                    )}
                  </ul>
                </div>
              </div>
            )}
            {data.dietCategory && (
              <div className="info-div">
                <h5>Diet Category</h5>
                <div>
                  <ul>
                    {data.dietCategory.length > 0 ? (
                      data.dietCategory.map((category, index) => (
                        <li key={index}>
                          {category[0].toUpperCase() +
                            category.slice(1)}
                        </li>
                      ))
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </div>
              </div>
            )}
            {data.alternativeRecipes && (
              <div className="info-div">
                <h5>Alternative Recipes</h5>
                <div>
                  <ul>
                    {data.alternativeRecipes.length > 0 ? (
                      data.alternativeRecipes.map((recipeName, index) => (
                        <li key={index}>{recipeName}</li>
                      ))
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </div>
              </div>
            )}
            {/* {data.healthyAlternativeRecipes && (
              <div className="info-div">
                <h5>Healthy Alternative Recipes</h5>
                <div>
                  <ul>
                    {data.healthyAlternativeRecipes.length > 0 ? (
                      data.healthyAlternativeRecipes.map(
                        (recipeName, index) => <li key={index}>{recipeName}</li>
                      )
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className="steps">
          <h5>Steps</h5>
          {steps?.map((step, index) => (
            <div key={index}>
              <span>{index + 1}.</span>
              <p>{step}</p>
            </div>
          ))}
        </div>
      </div>
    </StyledDetails>
  );
}

const StyledDetails = styled.div`
  /* display: flex;
  flex-direction: column;
  gap: 20px; */
  background-color: var(--background-color);
  li {
    list-style: circle;
    line-height: 1.4;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    min-height: 46px;
    padding: 10px 0;
    h4 {
      /* margin-left: 2%; */
    }
  }
  .modal-active {
    z-index: 1000;
    position: sticky;
    left: 0;
    top: 0%;
    border-bottom: 1px solid var(--shadow);
    background-color: var(--background-color);
  }
  .content {
    width: 98%;
    margin: auto;
    .main {
      display: flex;
      gap: 20px;
      margin: 10px auto;
      .img-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        min-width: 150px;
        min-height: 100px;

        .img {
          position: relative;
          img {
            width: 100%;
            border-radius: var(--radius);
          }
        }
        .btns {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        .favorite-btn {
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 50px;
          min-width: 25px;
          height: 25px;
          position: absolute;
          top: 5%;
          right: 5%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        p {
          max-width: 45ch;
        }
        .details {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 6px;
          input {
            width: 70px;
            padding: 0px 8px;
            border-color: var(--primary-color);
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            opacity: 1;
          }
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
          }
          h5 {
            color: var(--secondary-dark-color);
          }
          p {
            font-weight: bold;
          }
        }
      }
    }
    .mid {
      .ingredients,
      .extra-info {
        flex: 1 1 50%;
      }
      .ingredients {
        p {
          display: inline-block;
        }
      }
      .info-div {
        margin-bottom: 10px;
      }
    }
    .steps {
      div {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 4px 0;
        span {
          /* display: inline-block; */
          font-weight: 600;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .main {
      flex-wrap: wrap;
      justify-content: center;
      .info {
        width: 100%;
      }
    }
    .content .main .img-div {
      width: 100%;
    }
  }
  @media (min-width: 1000px) {
    .img-div {
      width: 320px;
    }
  }
`;

export default RecipeDetails;
