import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../authentication/firebase";

export async function fetchRecipesByIds(ids) {
  try {
    const q = query(
      collection(db, "gptRecipes"),
      where(documentId(), "in", ids)
    );
    const documentSnapshots = await getDocs(q);
    let fetchedRecipes = [];
    documentSnapshots.forEach((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        fetchedRecipes.push(documentSnapshot.data());
      } else {
        console.log(`Document with ID ${documentSnapshot.id} does not exist.`);
      }
    });
    return fetchedRecipes;
  } catch (error) {
    console.log("Error fetching recipes:", error);
  }
}

export async function fetchSingleRecipeById(recipeId, recipeType = "gpt") {
  try {
    const document = await getDoc(doc(db, `${recipeType}Recipes`, recipeId));
    return document.data();
  } catch (error) {
    console.log("Error fetching recipe:", error);
  }
}
