import { doc, writeBatch } from "firebase/firestore";
import { db } from "../authentication/firebase";
import { useDispatch, useSelector } from "react-redux";
import { mealPlanningActions } from "../store/mealPlanningSlice";
import { alertActions } from "../store/alertSlice";
import moment from "moment";
import { modalActions } from "../store/modalSlice";

export default function useAddRecipe() {
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.user.uid);

  async function addRecipeHandler(
    date,
    data,
    mealType,
    selectedServings,
    loginModalUid
  ) {
    data = { ...JSON.parse(JSON.stringify(data)) };
    const yy = date.split("-")[0];
    const mm = date.split("-")[1];
    const dd = date.split("-")[2];
    const batch = writeBatch(db);

    const finalUid = uid ? uid : loginModalUid;
    try {
      if (finalUid) {
        console.log(
          "add recipe",
          date,
          data,
          mealType,
          selectedServings,
          finalUid
        );
        const mealRef = doc(db, `dailyMeals/${finalUid}/${yy}-${mm}`, dd);
        batch.set(
          mealRef,
          {
            [mealType]: data,
          },
          { merge: true }
        );
        if (data.nutrition) {
          const nutrition = data.nutrition?.nutrients?.filter(
            (item) =>
              item.name === "Fat" ||
              item.name === "Carbohydrates" ||
              item.name === "Sugar" ||
              item.name === "Protein" ||
              item.name === "Calories"
          );
          const mealsNutritionRef = doc(
            db,
            `mealsNutrition/${finalUid}/${yy}-${mm}`,
            dd
          );
          batch.set(
            mealsNutritionRef,
            {
              [mealType]: nutrition,
            },
            { merge: true }
          );
        }
        let ingredients = {};
        data.extendedIngredients.forEach((item) => {
          let ingredient = { ...item };
          if (parseInt(selectedServings) !== 0) {
            ingredient.amount = parseFloat(
              (
                (ingredient.amount / data.servings) *
                (selectedServings || 1)
              ).toFixed(2)
            );
            console.log("ingre", ingredient.measures);
            if (ingredient.measures.metric)
              ingredient.measures.metric.amount = parseFloat(
                (
                  (ingredient.measures.metric.amount / data.servings) *
                  parseInt(selectedServings || 1)
                ).toFixed(2)
              );
            if (ingredient.measures.us)
              ingredient.measures.us.amount = parseFloat(
                (
                  (ingredient.measures.us.amount / data.servings) *
                  parseInt(selectedServings || 1)
                ).toFixed(2)
              );
            ingredients[ingredient.nameClean] = ingredient;
          }
        });
        if (moment(date).isSameOrAfter(moment().format("YYYY-MM-DD"))) {
          const cartRef = doc(db, `shoppingCart/${finalUid}/${yy}-${mm}`, dd);
          batch.set(
            cartRef,
            {
              [mealType]: ingredients,
            },
            { merge: true }
          );
        }
        await batch.commit();
        dispatch(
          mealPlanningActions.updateMeals({
            mealType,
            data,
            date: `${yy}-${mm}-${dd}`,
          })
        );
        dispatch(
          alertActions.setAlert({
            title: "Recipe added successfully",
            messageType: "success",
          })
        );
        window.dataLayer.push({
          event: "recipe-added",
          recipeId: data.id,
        });
      } else {
        console.log("recipe type", data.recipeType);
        // localStorage.setItem("stackedRecipe", JSON.stringify({id: data.id, recipeType: data.recipeType, date, mealType, selectedServings}))
        localStorage.setItem("routePath", `/recipe/${data.id}`);
        dispatch(modalActions.setLoginModalActive(true));
      }
    } catch (error) {
      console.log("error", error);
      dispatch(
        alertActions.setAlert({
          title: "Failed to add recipe",
          messageType: "error",
        })
      );
    }
  }
  return addRecipeHandler;
}
