import React, { useEffect, useState } from 'react';

const InstacartWidget = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const scriptUrl = 'https://widgets.instacart.com/widget-bundle-v2.js';
    const scriptId = 'standard-instacart-widget-v1';

    if (document.getElementById(scriptId)) {
      setIsScriptLoaded(true);
      return;
    }

    const handleScriptLoad = () => {
      setIsScriptLoaded(true);
    };

    const scriptElement = document.createElement('script');
    scriptElement.id = scriptId;
    scriptElement.src = scriptUrl;
    scriptElement.async = true;
    scriptElement.dataset.source_origin = 'affiliate_hub';

    scriptElement.addEventListener('load', handleScriptLoad);

    document.head.appendChild(scriptElement);

    return () => {
      scriptElement.removeEventListener('load', handleScriptLoad);
      document.head.removeChild(scriptElement);
    };
  }, []);

  return (
    <div id="shop-with-instacart-v1" data-affiliate_id="3003" data-source_origin="affiliate_hub" data-affiliate_platform="recipe_widget">
      {isScriptLoaded && (
        <div dangerouslySetInnerHTML={{ __html: `<script>(function(d, s, id, a) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) { return; } js = d.createElement(s); js.id = id; js.src = "https://widgets.instacart.com/widget-bundle-v2.js"; js.async = true; js.dataset.source_origin = "affiliate_hub"; fjs.parentNode.insertBefore(js, fjs); })(document, "script", "standard-instacart-widget-v1");</script>` }} />
      )}
    </div>
  );
};

export default InstacartWidget;
