import algoliasearch from "algoliasearch/lite";
import { fetchRecipesByIds } from "./recipesHandler";

let pageNo = 0;

const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
const applicationId = process.env.REACT_APP_ALGOLIA_APLICATION_ID;

const client = algoliasearch(applicationId, apiKey);
const index = client.initIndex("gptRecipes");


// Function to convert comma seperated strings("pakistani, indian") into seperate strings("pakistani", "indian")
function stringsToArray(initailArray) {
  let commaSeperatedArray = [];
  if (initailArray?.length > 0) {
    initailArray.forEach((name) => {
      commaSeperatedArray.push(...name.split(","));
    });
  }
  return commaSeperatedArray;
}

function convertToFilterString(name, values) {
  let seperatedArray = stringsToArray(values);
  let filtersString = "";
  seperatedArray?.forEach((filterValue, index) => {
    // putting filter inside quotation is important
    const filter = `${name}:'${filterValue}'`;
    if (index > 0) {
      filtersString += ` OR ${filter}`;
    } else if (index === 0) {
      filtersString += `(${filter}`;
    }
  });
  if (filtersString.length > 0) filtersString += ")";
  return filtersString;
}

export async function searchAlgolia({
  query = "",
  cuisines = [],
  maxReadyTime = 10000,
  fetchNextPage = false,
  noOfRecipes = 25,
  dietCategory = []
}) {
  pageNo = fetchNextPage ? pageNo + 1 : 0;
  let cuisinesFilter = convertToFilterString("cuisines", cuisines);
  let dietCategoryFilter = convertToFilterString("dietCategory", dietCategory);
  const algoliFilters = `readyInMinutes<=${maxReadyTime} ${cuisinesFilter?.length === 0 ? "" : `AND ${cuisinesFilter}`} ${
    dietCategoryFilter?.length === 0 ? "" : `AND ${dietCategoryFilter}`
  }`;

  console.log("algo filters", algoliFilters)

  // try {
  const response = await index.search(query, {
    filters: algoliFilters,
    hitsPerPage: noOfRecipes,
    page: pageNo,
  });
  const hits = response.hits;

  console.log("algolia hits", hits);
  if (hits?.length > 0) {
    let ids = [];
    hits.forEach((hit) => {
      ids.push(hit.objectID);
    });
    return await fetchRecipesByIds(ids);
  } else {
    return [];
  }
  // } catch (e) {
  //   console.log("error while fetching algolia recipes", e);
  // }
}
