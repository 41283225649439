import { deleteDoc, doc, setDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { db } from "../../authentication/firebase";
import { useDispatch, useSelector } from "react-redux";
import { userRecipesActions } from "../../store/userRecipesSlice";
import { alertActions } from "../../store/alertSlice";
import { CircularProgress, Fade, Tooltip } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

export default function FavoriteRecipeHandler({data}) {
  const [favorite, setFavorite] = useState({
    checked: data.recipeType === "favorite",
    loading: false,
  });
  
  const uid = useSelector((state) => state.user.uid);
  const dispatch = useDispatch();

  async function addFavoriteHandler() {
    setFavorite((prev) => {
      return { ...prev, loading: true };
    });
    try {
      await setDoc(doc(db, "favoriteRecipes", data.id.toString()), {
        ...data,
        recipeType: "favorite",
        uid,
      });
      setFavorite({ checked: true, loading: false });
      dispatch(
        userRecipesActions.appendUserRecipes({ data, recipeType: "favorite" })
      );
      dispatch(
        alertActions.setAlert({
          title: "Recipe added to favorites",
          messageType: "success",
        })
      );
      window.dataLayer.push({
        event: "recipe-added-to-favorites",
        recipeId: data.id
      })
    } catch (error) {
      console.log("error", error);
      // dispatch(
      //   alertActions.setAlert({
      //     title: "Failed to add to favorites",
      //     messageType: "error",
      //   })
      // );
    }
    setFavorite((prev) => {
      return { ...prev, loading: false };
    });
  }

  const removeFavoriteHandler = async () => {
    console.log("docId", data.id);
    setFavorite((prev) => {
      return { ...prev, loading: true };
    });
    try {
      await deleteDoc(doc(db, "favoriteRecipes", data.id.toString()));
      setFavorite({ checked: false, loading: false });
      dispatch(
        userRecipesActions.deleteUserRecipe({ data, recipeType: "favorite" })
      );
      dispatch(
        alertActions.setAlert({
          title: "Recipe removed from favorites",
          messageType: "success",
        })
      );
      window.dataLayer({
        event: "recipe-removed-from-favorites",
        recipeId: data.id
      })
    } catch (error) {
      console.log("error", error);
      // dispatch(
      //   alertActions.setAlert({
      //     title: "Failed to unfavorite recipe",
      //     messageType: "error",
      //   })
      // );
    }
    setFavorite((prev) => {
      return { ...prev, loading: false };
    });
  };
  return favorite.loading ? (
    <button className="icon favorite-btn">
      <CircularProgress
        style={{ color: "white", width: "20px", height: "20px" }}
      />
    </button>
  ) : favorite.checked ? (
    <Tooltip title="Unmark Favorite">
      <button onClick={removeFavoriteHandler} className="icon favorite-btn">
        <Fade in={favorite.checked}>
          <FavoriteIcon fontSize="small" htmlColor="var(--accent-color)" />
        </Fade>
      </button>
    </Tooltip>
  ) : (
    <Tooltip title="Mark as Favorite">
      <button onClick={addFavoriteHandler} className="icon favorite-btn">
        <Fade in={!favorite.checked}>
          <FavoriteBorderIcon fontSize="small" htmlColor="white" />
        </Fade>
      </button>
    </Tooltip>
  );
}
