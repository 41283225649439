const profileTabItems = {
  pantry_essentials:{
    // name: "Pantry Essentials",
    values: [
      "baking powder",
      "baking soda",
      "basil",
      "bell pepper",
      "bread",
      "brown sugar",
      "butter",
      "carrot",
      "cheddar",
      "chicken breast",
      "chili powder",
      "cinnamon",
      "cumin",
      "egg",
      "flour",
      "garlic",
      "garlic powder",
      "honey",
      "italian seasoning",
      "ketchup",
      "mayonnaise",
      "milk",
      "mustard",
      "olive oil",
      "onion",
      "onion powder",
      "oregano",
      "paprika",
      "parmesan",
      "parsley",
      "peanut butter",
      "potato",
      "rice",
      "soy sauce",
      "spaghetti",
      "sugar",
      "thyme",
      "tomato",
      "vanilla",
      "vegetable oil",
    ],
  },
  vegetables_and_greens:{
    // name: "Vegetables and Greens",
    values: [
      "acorn squash",
      "anaheim pepper",
      "ancho chile peppers",
      "artichoke",
      "arugula",
      "asparagus",
      "avocado",
      "baby bok choy",
      "baby corn",
      "baby greens",
      "bamboo shoot",
      "bean sprouts",
      "beetroot",
      "belgian endive",
      "bell pepper",
      "black olives",
      "bok choy",
      "boston lettuce",
      "broccoli",
      "broccoli rabe",
      "brussels sprout",
      "butter lettuce",
      "butternut squash",
      "cabbage",
      "canned pumpkin",
      "canned tomato",
      "canned whole tomato",
      "carrot",
      "cauliflower",
      "celeriac",
      "celery",
      "chard",
      "cherry tomato",
      "chile pepper",
      "collard greens",
      "corn cob",
      "corn husk",
      "cress",
      "cucumber",
      "daikon",
      "delicata squash",
      "eggplant",
      "endive",
      "fennel",
      "frisee",
      "garlic",
      "green olives",
      "green tomato",
      "guajillo pepper",
      "habanero pepper",
      "horseradish",
      "iceberg",
      "jalapeno",
      "jicama",
      "kalamata olives",
      "kale",
      "kohlrabi",
      "leaf lettuce",
      "leek",
      "lettuce",
      "mashed potatoes",
      "microgreens",
      "mixed greens",
      "mixed vegetable",
      "napa cabbage",
      "okra",
      "onion",
      "parsnip",
      "pearl onion",
      "pepperoncini",
      "pimiento",
      "plantain",
      "poblano pepper",
      "potato",
      "pumpkin",
      "radicchio",
      "radish",
      "red cabbage",
      "red onion",
      "romaine",
      "rutabaga",
      "sauerkraut",
      "scallion",
      "serrano pepper",
      "shallot",
      "spaghetti squash",
      "spinach",
      "summer squash",
      "sweet corn",
      "sweet onion",
      "sweet pepper",
      "sweet potato",
      "tenderstem broccoli",
      "thai chile pepper",
      "tomato",
      "turnip",
      "water chestnut",
      "watercress",
      "yam",
      "zucchini",
    ],
  },
  mushrooms:{
    // name: "Mushrooms",
    values: [
      "black fungus",
      "black truffle",
      "boletus",
      "caesar's mushroom",
      "candy cap mushrooms",
      "canned mushroom",
      "chestnut mushroom",
      "djon djon mushroom",
      "enoki mushroom",
      "field mushroom",
      "honey fungus",
      "huitlacoche",
      "maitake",
      "matsutake",
      "mixed mushrooms",
      "morel mushrooms",
      "mushroom",
      "nameko",
      "oyster mushroom",
      "pioppini",
      "porcini",
      "portobello mushroom",
      "puffball",
      "shiitake mushroom",
      "shimeji mushroom",
      "snow fungus",
      "straw mushroom",
      "trumpet mushroom",
      "white truffle",
    ],
  },
  fruits:{
    // name: "Fruits",
    values: [
      "apple",
      "apricot",
      "asian pear",
      "banana",
      "bitter melon",
      "bitter orange",
      "calamansi",
      "canned peaches",
      "canned pear",
      "canned pineapple",
      "cantaloupe",
      "chestnut",
      "chestnut puree",
      "clementine",
      "coconut",
      "crabapple",
      "craisins",
      "currant",
      "custard-apple",
      "date",
      "dragon fruit",
      "dried apricot",
      "dried fig",
      "dried fruit",
      "dried lemon",
      "dried lime",
      "dried mango",
      "dried orange slice",
      "dried persimmons",
      "durian",
      "egg fruit",
      "feijoa",
      "fig",
      "fig leaves",
      "fruit cocktail",
      "granadilla",
      "grape",
      "grapefruit",
      "guava",
      "honey date",
      "honeydew melon",
      "jackfruit",
      "jujube",
      "kaffir lime",
      "kaffir lime leaves",
      "kiwano",
      "kiwi",
      "kumquat",
      "lemon",
      "lime",
      "longan",
      "loquat",
      "lucuma",
      "luo han guo",
      "lychee",
      "mamey",
      "mandarin",
      "mango",
      "mangosteen",
      "medjool date",
      "melon",
      "meyer lemon",
      "mixed peel",
      "nectarine",
      "orange",
      "papaya",
      "passion fruit",
      "peach",
      "pear",
      "persimmon",
      "physalis",
      "pineapple",
      "plum",
      "pomegranate",
      "pomelo",
      "prickly pear",
      "prunes",
      "quince",
      "raisins",
      "rambutan",
      "rhubarb",
      "soursop",
      "star fruit",
      "sultanas",
      "sweet lime",
      "tamarillo",
      "tangelo",
      "tangerine",
      "tender coconut",
      "watermelon",
      "watermelon purée",
      "young jackfruit",
      "yuzu",
    ],
  },
  berries: {
    // name: "Berries",
    values: [
      "acai berry",
      "barberry",
      "berry mix",
      "blackberry",
      "blackcurrant leaves",
      "blueberry",
      "boysenberry",
      "canned sour cherry",
      "cherry",
      "chokeberry",
      "cloudberry",
      "cranberry",
      "dewberry",
      "dried blueberries",
      "dried tart cherry",
      "elderberry",
      "freeze-dried strawberry",
      "goji berry",
      "gooseberry",
      "haskap berry",
      "hawthorn",
      "huckleberry",
      "juniper berry",
      "lingonberry",
      "loganberry",
      "maraschino cherry",
      "mulberry",
      "oregon grape",
      "raspberry",
      "rosehip",
      "saskatoon berry",
      "sea buckthorn",
      "sloe berry",
      "sour cherry",
      "strawberry",
    ],
  },
  nuts_and_seeds:{
    // name: "Nuts and Seeds",
    values: [
      "almond",
      "apricot kernels",
      "barùkas",
      "basil seeds",
      "brazil nut",
      "candlenut",
      "cashew",
      "chia",
      "chopped nuts",
      "flax",
      "hazelnut",
      "hemp hearts",
      "keto trail mix",
      "lotus seeds",
      "macadamia",
      "mixed nuts",
      "mixed seeds",
      "nigella seeds",
      "palm seeds",
      "peanut",
      "pecan",
      "pine nut",
      "pistachio",
      "poppy seed",
      "pumpkin seeds",
      "sesame seed",
      "sunflower seeds",
      "tigernuts",
      "trail mix",
      "walnut",
      "white chia",
    ],
  },
  cheeses:{
    // name: "Cheeses",
    values: [
      "aged cheddar",
      "american cheese",
      "amul cheese",
      "anthotyro",
      "asadero",
      "asiago",
      "babybel",
      "blue cheese",
      "brick cheese",
      "brie",
      "brown cheese",
      "bulgarian cheese",
      "burrata",
      "caciocavallo",
      "cambozola",
      "camembert",
      "cheddar",
      "cheddar cheese powder",
      "cheese powder",
      "cheshire cheese",
      "chevre",
      "colby",
      "colby-jack cheese",
      "comté",
      "cotija",
      "cottage cheese",
      "cream cheese",
      "derby cheese",
      "edam",
      "epoisses cheese",
      "farmer's cheese",
      "feta",
      "fontal",
      "fontina",
      "fresh mozzarella",
      "fromage blanc",
      "gloucester",
      "goat cheese",
      "gouda",
      "grana-padano",
      "graviera",
      "gruyere",
      "halloumi",
      "havarti",
      "herb cream cheese",
      "italian cheese blend",
      "jarlsberg cheese",
      "kashkaval",
      "kasseri",
      "kefalotyri",
      "labneh",
      "lancashire",
      "laughing cow",
      "leicester",
      "longhorn",
      "maasdam",
      "manchego",
      "manouri",
      "marble cheese",
      "mascarpone",
      "mexican cheese blend",
      "mimolette",
      "mizithra",
      "monterey jack",
      "mozzarella",
      "muenster",
      "neufchatel",
      "oaxaca",
      "paneer",
      "panela cheese",
      "parmesan",
      "pecorino",
      "pepper jack",
      "petit-suisse",
      "port salut",
      "provolone",
      "quark",
      "queso anejo",
      "queso fresco",
      "queso quesadilla",
      "raclette",
      "reblochon",
      "requeijão",
      "ricotta",
      "robiola",
      "sbrinz",
      "scamorza",
      "shanklish",
      "sharp cheddar",
      "sheep cheese",
      "smoked cheese",
      "soft cheese",
      "stilton",
      "swiss cheese",
      "taleggio",
      "teleme",
      "tilsit",
      "trappist cheese",
      "truffle cheese",
      "wensleydale",
    ],
  },
  dairy_and_eggs:{
    // name: "Dairy and Eggs",
    values: [
      "ayran",
      "buffalo milk",
      "bulgarian yogurt",
      "butter",
      "buttermilk",
      "buttermilk powder",
      "cajeta",
      "century egg",
      "chantilly",
      "cheese curd",
      "chocolate milk",
      "chocolate milk powder",
      "clotted cream",
      "coffee creamer",
      "condensed milk",
      "cream",
      "creme fraiche",
      "cuajada",
      "curd",
      "custard",
      "dahi",
      "duck egg",
      "dulce de leche",
      "egg",
      "evaporated milk",
      "frosting",
      "frozen yogurt",
      "ganache",
      "ghee",
      "goat butter",
      "goat milk",
      "goat yogurt",
      "greek yogurt",
      "half and half",
      "heavy cream",
      "hung curd",
      "ice cream",
      "kashk",
      "kefir",
      "lemon curd",
      "liquid rennet",
      "malai",
      "margarine",
      "milk",
      "milk powder",
      "milkfat",
      "orange curd",
      "pickled egg",
      "quail egg",
      "rainbow sherbet",
      "raw milk",
      "salted egg",
      "sheep milk",
      "sheep yogurt",
      "sherbet",
      "skyr",
      "sour cream",
      "sour milk",
      "starter culture",
      "strawberry milk",
      "sweetened condensed milk",
      "thickened cream",
      "whey",
      "whipped cream",
      "yogurt",
      "yogurt drink",
      "yogurt starter",
    ],
  },
  dairy_free_and_meat_substitutes:{
    // name: "Dairy-Free and Meat substitutes",
    values: [
      "almond butter",
      "almond creamer",
      "almond milk",
      "almond-coconut milk",
      "almond-milk yogurt",
      "banana blossom",
      "banana milk",
      "cashew butter",
      "cashew cheese sauce",
      "cashew cream cheese",
      "cashew milk",
      "coconut aminos",
      "coconut butter",
      "coconut cream",
      "coconut fat",
      "coconut milk",
      "coconut paste",
      "coconut powder",
      "coconut whipped cream",
      "coconut yogurt",
      "condensed coconut milk",
      "dairy-free ice-cream",
      "egg replacer",
      "egg tofu",
      "extra firm tofu",
      "falafel mix",
      "flax milk",
      "hazelnut butter",
      "hazelnut creamer",
      "hazelnut milk",
      "hemp milk",
      "hemp protein",
      "kala namak salt",
      "latik",
      "macadamia butter",
      "macadamia milk",
      "non-dairy cream",
      "non-dairy cream cheese",
      "non-dairy creamer",
      "non-dairy milk",
      "non-dairy whipped topping",
      "non-dairy yogurt",
      "nut butter",
      "nut milk",
      "oat milk",
      "okara",
      "pulled oats",
      "quorn",
      "ready-made falafel",
      "rice milk",
      "seed butter",
      "seitan",
      "silken tofu",
      "smoked tofu",
      "soy chorizo",
      "soy cream",
      "soy curls",
      "soy granules",
      "soy milk",
      "soy milk powder",
      "soy yogurt",
      "soy-free vegan butter",
      "tempeh",
      "textured vegetable protein",
      "tofu",
      "tofurky",
      "vegan bacon",
      "vegan beef",
      "vegan burger patty",
      "vegan butter",
      "vegan cheese",
      "vegan chicken",
      "vegan chicken nuggets",
      "vegan cream cheese",
      "vegan gravy",
      "vegan ground beef",
      "vegan mayonnaise",
      "vegan meatballs",
      "vegan mozzarella",
      "vegan nutella",
      "vegan pesto",
      "vegan roe",
      "vegan sausage",
      "vegan sour cream",
      "vegan starter culture",
      "vegan yogurt starter",
      "vegetable suet",
      "vegetarian hot dog",
      "vegetarian rennet",
      "walnut milk",
      "walnut taco meat",
    ],
  },
  meats:{
    // name: "Meats",
    values: [
      "andouille",
      "back bacon",
      "bacon",
      "bacon bits",
      "beef liver",
      "beef ribs",
      "beef roast",
      "beef sausage",
      "beef shank",
      "beef short ribs",
      "beef shoulder",
      "beef sirloin",
      "beef steak",
      "beef stew meat",
      "beef suet",
      "beef tongue",
      "black forest ham",
      "blood sausage",
      "bologna",
      "bone-in ham",
      "boneless lamb",
      "bratwurst",
      "breakfast sausage",
      "brisket",
      "burger patty",
      "canadian bacon",
      "canned pork",
      "chorizo",
      "cocktail sausage",
      "corned beef",
      "country ham",
      "deli ham",
      "dried beef",
      "frozen meatballs",
      "gammon joint",
      "ground beef",
      "ground bison",
      "ground lamb",
      "ground pork",
      "ground pork sausage",
      "ground sausage",
      "ground venison",
      "guanciale",
      "ham",
      "ham hock",
      "ham steak",
      "hard salami",
      "hot dog",
      "hot italian sausage",
      "italian sausage",
      "kielbasa",
      "lamb chops",
      "lamb loin",
      "lamb neck",
      "lamb shank",
      "lamb shoulder",
      "lap cheong",
      "leg of lamb",
      "mixed ground meat",
      "mortadella",
      "mutton",
      "oxtail",
      "pancetta",
      "pastrami",
      "pepperoni",
      "pork back ribs",
      "pork belly",
      "pork butt",
      "pork chops",
      "pork cutlets",
      "pork fillet",
      "pork loin",
      "pork ribs",
      "pork roast",
      "pork shoulder",
      "pork spare ribs",
      "prosciutto",
      "pulled pork",
      "rabbit",
      "rack of lamb",
      "roast beef",
      "salami",
      "salt pork",
      "sausage",
      "sausage patty",
      "serrano ham",
      "smoked ham hock",
      "smoked pork chop",
      "smoked sausage",
      "snail",
      "soup bones",
      "spam",
      "sweet italian sausage",
      "veal chops",
      "veal cutlet",
      "veal roast",
      "veal shank",
      "veal steak",
      "venison",
      "venison steak",
    ],
  },
  poultry:{
    // name: "Poultry",
    values: [
      "boneless chicken",
      "capon",
      "chicken bones",
      "chicken breast",
      "chicken feet",
      "chicken frames",
      "chicken giblets",
      "chicken gizzards",
      "chicken gyros",
      "chicken ham",
      "chicken kebab",
      "chicken leg",
      "chicken livers",
      "chicken meatballs",
      "chicken nuggets",
      "chicken patty",
      "chicken quarters",
      "chicken ribs",
      "chicken roast",
      "chicken sausage",
      "chicken schnitzel",
      "chicken tenders",
      "chicken thighs",
      "chicken wings",
      "cooked chicken",
      "cornish hen",
      "crispy chicken strips",
      "deli chicken",
      "deli turkey",
      "duck",
      "duck breast",
      "duck confit",
      "duck liver",
      "duck neck",
      "foie gras",
      "goose",
      "goose liver",
      "ground chicken",
      "ground turkey",
      "ground turkey sausage",
      "grouse",
      "guinea fowl",
      "ostrich",
      "pheasant",
      "pigeon",
      "popcorn chicken",
      "quail",
      "rotisserie chicken",
      "smoked chicken",
      "smoked chicken sausage",
      "smoked duck breast",
      "smoked turkey",
      "smoked turkey leg",
      "smoked turkey sausage",
      "smoked turkey wing",
      "turkey bacon",
      "turkey breast",
      "turkey burger",
      "turkey ham",
      "turkey kielbasa",
      "turkey leg",
      "turkey liver",
      "turkey lunch meat",
      "turkey meatballs",
      "turkey neck",
      "turkey pepperoni",
      "turkey sausage",
      "turkey tail",
      "turkey thigh",
      "turkey wing",
      "whole chicken",
      "whole turkey",
      "wild game bird",
    ],
  },
  fish:{
    // name: "Fish",
    values: [
      "amberjack",
      "arctic char",
      "bangus",
      "barramundi",
      "basa fish",
      "battered fish",
      "black cod",
      "bluefish",
      "bombay duck",
      "bottarga",
      "branzino",
      "canned anchovy",
      "canned salmon",
      "canned sardines",
      "canned tuna",
      "carp",
      "catfish",
      "caviar",
      "cod",
      "cuttlefish",
      "dried anchovy",
      "eel",
      "fish balls",
      "fish cake",
      "fish fillets",
      "fish heads",
      "fish pie mix",
      "flathead",
      "flounder",
      "fresh anchovy",
      "fresh mackerel",
      "garoupa",
      "gilt-head bream",
      "grouper",
      "gurnard",
      "haddock",
      "hake",
      "halibut",
      "herring",
      "john dory",
      "kamaboko",
      "kingfish",
      "korean fish cake",
      "lemon sole",
      "mackerel",
      "mahi mahi",
      "marlin",
      "monkfish",
      "mullet",
      "orange roughy",
      "pangasius",
      "perch",
      "pickled herring",
      "pike",
      "plaice",
      "pollock",
      "pomfret",
      "pompano",
      "rainbow trout",
      "red mullet",
      "red snapper",
      "rockfish",
      "roe",
      "rohu fish",
      "salmon",
      "salmon burger meat",
      "salmon roe",
      "salmon trout",
      "salt cod",
      "salt herring",
      "sardines",
      "sardines in tomato sauce",
      "sea bass",
      "sea bream",
      "shark meat",
      "skipjack tuna",
      "smoked eel",
      "smoked fish",
      "smoked haddock",
      "smoked herring",
      "smoked mackerel",
      "smoked salmon",
      "smoked trout",
      "sole",
      "soused herring",
      "sturgeon",
      "surimi",
      "swai fish",
      "swordfish",
      "tilapia",
      "tinapa",
      "tiny fish",
      "trout",
      "tuna steak",
      "turbot",
      "walleye",
      "whitefish",
      "whiting",
      "yellowtail",
      "zander",
    ],
  },
  herbs_and_spices:{
    // name: "Herbs and Spices",
    values: [
      "achiote paste",
      "achiote seeds",
      "aleppo pepper",
      "allspice",
      "ancho chile powder",
      "aniseed",
      "arbol chile pepper",
      "asafoetida",
      "basil",
      "bay leaf",
      "black cardamom",
      "caraway",
      "cardamom",
      "carom seeds",
      "cayenne",
      "celery flakes",
      "celery powder",
      "celery seed",
      "chervil",
      "chile powder",
      "chili flake",
      "chipotle",
      "chipotle powder",
      "chive",
      "cilantro",
      "cinnamon",
      "clove",
      "coriander",
      "coriander powder",
      "cracked pepper",
      "cumin",
      "curry leaves",
      "dill",
      "dill seed",
      "dried chilies",
      "dried lemon peel",
      "dried orange peel",
      "dried parsley flakes",
      "edible flowers",
      "epazote",
      "espelette pepper",
      "fennel seed",
      "fenugreek",
      "fenugreek seed",
      "fresh mint",
      "galangal",
      "garlic flakes",
      "garlic granules",
      "garlic powder",
      "ginger powder",
      "ginger root",
      "gochugaru",
      "green cardamom",
      "green peppercorn",
      "ground nutmeg",
      "hibiscus flowers",
      "hibiscus leaves",
      "hot paprika",
      "kali mirch",
      "kashmiri chilli powder",
      "kashmiri red chilli",
      "lavender",
      "lemon balm",
      "lemon basil",
      "lemon thyme",
      "lemon verbena",
      "lemongrass",
      "lemongrass paste",
      "mace",
      "marjoram",
      "matcha powder",
      "mint",
      "mustard seed",
      "nutmeg",
      "onion flake",
      "onion powder",
      "oregano",
      "pandan leaves",
      "paprika",
      "parsley",
      "peppercorn",
      "pink peppercorn",
      "rosemary",
      "saffron",
      "saffron strands",
      "sage",
      "saigon cinnamon",
      "savory herb",
      "shiso",
      "smoked paprika",
      "sorrel",
      "star anise",
      "sumac",
      "tarragon",
      "thai basil",
      "thai peppers",
      "thyme",
      "turmeric",
      "vietnamese mint",
      "white pepper",
    ],
  },
  sugar_and_sweeteners:{
    // name: "Sugar and Sweeteners",
    values: [
      "agave nectar",
      "allulose",
      "apple syrup",
      "aspartame",
      "berry syrup",
      "black treacle",
      "blackberry syrup",
      "blackstrap molasses",
      "blueberry syrup",
      "brown sugar",
      "brown sugar blend",
      "buttermilk syrup",
      "cane syrup",
      "caramel syrup",
      "carob syrup",
      "cherry syrup",
      "chocolate sugar",
      "chocolate syrup",
      "cinnamon sugar",
      "coarse sugar",
      "coconut sugar",
      "coconut syrup",
      "coffee-flavored syrup",
      "confectioners' sweetener",
      "confectioners’ sugar",
      "corn syrup",
      "creamed honey",
      "dark corn syrup",
      "date paste",
      "date sugar",
      "date syrup",
      "demerara sugar",
      "elderberry syrup",
      "erythritol",
      "flavored syrup",
      "fresh sugar cane",
      "fructose",
      "ginger syrup",
      "glucose",
      "glycerine",
      "golden syrup",
      "grape syrup",
      "grenadine",
      "gula melaka",
      "gum syrup",
      "hibiscus syrup",
      "honey",
      "honey powder",
      "inulin",
      "isomalt",
      "jam sugar",
      "liquid stevia",
      "lucuma powder",
      "malt sugar",
      "malt syrup",
      "mango syrup",
      "maple butter",
      "maple sugar",
      "maple syrup",
      "mastic",
      "mint syrup",
      "molasses",
      "monk fruit sweetener",
      "orgeat",
      "pancake syrup",
      "piloncillo",
      "prickly pear syrup",
      "raspberry syrup",
      "raw sugar",
      "rice malt syrup",
      "rice syrup",
      "salted caramel syrup",
      "sanding sugar",
      "sorghum syrup",
      "splenda brown sugar",
      "stevia",
      "strawberry syrup",
      "sucralose",
      "sugar",
      "sugar cane juice",
      "sugar syrup",
      "sweet'n low",
      "topinambur syrup",
      "treacle",
      "vanilla sugar",
      "vanilla syrup",
      "xylitol",
      "yacon syrup",
    ],
  },
  seasonings_and_spice_blends:{
    // name: "Seasonings and Spice Blends",
    values: [
      "adobo seasoning",
      "all-purpose seasoning",
      "apple pie spice",
      "au jus mix",
      "bagel seasoning",
      "baharat",
      "barbecue seasoning",
      "beau monde seasoning",
      "berbere",
      "berbere spice",
      "biryani masala",
      "blackening seasoning",
      "bouquet garni",
      "brown miso",
      "cajun seasoning",
      "caribbean jerk seasoning",
      "carne asada seasoning",
      "chaat masala",
      "chai masala",
      "chana masala",
      "chicken gravy mix",
      "chicken taco seasoning",
      "chili powder",
      "chili-lime seasoning",
      "chinese five spice",
      "chipotle seasoning",
      "citrus rub",
      "coffee rub",
      "crab boil seasoning",
      "creole seasoning",
      "curing salt",
      "curry",
      "dash seasoning",
      "doenjang",
      "dry ranch seasoning",
      "dukkah",
      "fajita seasoning",
      "fermented bean curd",
      "fines herbes",
      "fleur de sel",
      "furikake",
      "garam masala",
      "garlic & herb seasoning",
      "garlic-pepper seasoning",
      "gingerbread spice",
      "gravy mix",
      "greek seasoning",
      "green curry",
      "harissa spice blend",
      "hawaiian salt",
      "herbes de provence",
      "italian seasoning",
      "lemon & pepper seasoning",
      "liquid smoke",
      "mango powder",
      "meat tenderizer",
      "mesquite seasoning",
      "mexican seasoning",
      "mixed spice",
      "msg",
      "mulling spices",
      "mushroom seasoning",
      "mustard powder",
      "old bay seasoning",
      "panang curry",
      "panch phoron",
      "pasta seasoning",
      "pav bhaji masala",
      "pickling spice",
      "pizza seasoning",
      "poultry seasoning",
      "pumpkin pie spice",
      "ras el hanout",
      "rasam powder",
      "red curry",
      "red miso",
      "salad seasoning",
      "salt-free seasoning",
      "sambar powder",
      "seafood seasoning",
      "seasoned pepper",
      "seasoned salt",
      "shawarma seasoning",
      "shichimi togarashi",
      "southwest chipotle seasoning",
      "sriracha seasoning",
      "steak seasoning",
      "taco seasoning",
      "tajin seasoning",
      "tandoori spice",
      "teriyaki marinade",
      "thai red curry paste",
      "thai seasoning",
      "togarashi",
      "tom yum paste",
      "truffle salt",
      "wasabi powder",
      "white miso",
      "yellow miso",
      "za'atar",
    ],
  },
  baking:{
    // name: "Baking",
    values: [
      "agar agar",
      "almond extract",
      "almond flour",
      "almond meal",
      "angel food cake mix",
      "arrowroot flour",
      "baking chips",
      "baking mix",
      "baking powder",
      "baking soda",
      "bread flour",
      "brown rice flour",
      "brownie mix",
      "buckwheat flour",
      "cacao",
      "cacao nibs",
      "cake flour",
      "candy coating",
      "candy sprinkles",
      "canned pie filling",
      "cassava flour",
      "chickpea flour",
      "chocolate cake mix",
      "citric acid",
      "coconut chips",
      "coconut extract",
      "coconut flake",
      "coconut flour",
      "coffee bean",
      "coffee extract",
      "corn flour",
      "cornbread mix",
      "cornmeal",
      "cornstarch",
      "cream of tartar",
      "crystallized ginger",
      "dark cocoa",
      "desiccated coconut",
      "dutch-process cocoa",
      "einkorn flour",
      "essence",
      "flaxseed meal",
      "flour",
      "fondant",
      "food coloring",
      "gelatin",
      "gluten-free flour",
      "glutinous rice flour",
      "hazelnut flour",
      "jello",
      "lemon extract",
      "maple extract",
      "marzipan",
      "masa harina",
      "matzo meal",
      "meringue powder",
      "millet flour",
      "mincemeat",
      "mint extract",
      "muffin mix",
      "oat flour",
      "orange blossom water",
      "orange extract",
      "pancake mix",
      "panko",
      "pastry flour",
      "peanut flour",
      "pectin",
      "potato flakes",
      "potato starch",
      "psyllium husk",
      "quinoa flour",
      "red velvet cake mix",
      "refined flour",
      "rice flour",
      "rose water",
      "rum extract",
      "rye flour",
      "sago",
      "self-raising flour",
      "semolina flour",
      "shredded coconut",
      "sorghum flour",
      "spelt",
      "spice cake mix",
      "strawberry extract",
      "sugar cookie mix",
      "sweet bean paste",
      "sweet rice flour",
      "tapioca starch",
      "unbleached all-purpose flour",
      "vanilla",
      "vital wheat gluten",
      "whipped cream stabilizer",
      "white baking chips",
      "white cornmeal",
      "whole-wheat flour",
      "xanthan gum",
      "yeast",
      "yellow cake mix",
    ],
  },
  pre_made_doughs_and_wrappers:{
    // name: "Pre-Made Doughs and Wrappers",
    values: [
      "biscuit dough",
      "bread dough",
      "brick pastry",
      "brisee",
      "butter puff pastry",
      "chocolate pie crust",
      "cinnamon roll dough",
      "cookie dough",
      "corn dogs",
      "crescent dough",
      "croissant dough",
      "dosa batter",
      "dumpling wrapper",
      "egg roll wrapper",
      "empanada wrapper",
      "flaky biscuits",
      "fresh pasta dough",
      "frozen dinner roll",
      "gingerbread cookie dough",
      "gyoza wrapper",
      "idli batter",
      "kataifi",
      "korean rice cake",
      "phyllo",
      "pie crust",
      "pizza crust",
      "potsticker wrappers",
      "puff pastry",
      "puff pastry shells",
      "refrigerated crescent rolls",
      "rice paper",
      "rough-puff pastry",
      "sausage rolls",
      "sourdough starter",
      "tofu skins",
      "tortilla dough",
      "wontons",
      "yufka",
    ],
  },
  grains_and_cereals:{
    // name: "Grains and Cereals",
    values: [
      "amaranth",
      "barley",
      "basmati rice",
      "black glutinous rice",
      "black rice",
      "breakfast cereal",
      "brown rice",
      "bulgur",
      "cilantro lime rice",
      "couscous",
      "cream of rice",
      "cream of wheat",
      "dirty rice mix",
      "farro",
      "fine semolina",
      "flattened rice",
      "fonio",
      "freekeh",
      "gluten-free breakfast cereal",
      "glutinous rice",
      "granola cereal",
      "grits",
      "grünkern",
      "hominy",
      "hulled barley",
      "idli rice",
      "instant rice",
      "jambalaya rice mix",
      "jasmine rice",
      "kamut flakes",
      "kasha",
      "mexican rice",
      "millet",
      "muesli",
      "oat bran",
      "oat groats",
      "paella rice",
      "pearl barley",
      "pearled farro",
      "polenta",
      "puffed amaranth",
      "puffed kamut",
      "puffed quinoa",
      "puffed rice",
      "puffed wheat",
      "quick oats",
      "quinoa",
      "quinoa flakes",
      "raw buckwheat",
      "red quinoa",
      "red rice",
      "rice",
      "rice cereal",
      "rice pilaf",
      "risotto rice",
      "rolled oats",
      "rye berries",
      "rye flakes",
      "semolina",
      "shirataki rice",
      "sorghum",
      "spelt flakes",
      "steel cut oats",
      "sushi rice",
      "teff",
      "weetabix",
      "wheat berries",
      "wheat bran",
      "wheat germ",
      "wild rice",
      "yellow rice",
    ],
  },
  legumes:{
    // name: "Legumes",
    values: [
      "aquafaba",
      "black beans",
      "black lentils",
      "black soybeans",
      "black-eyed peas",
      "borlotti beans",
      "brown beans",
      "canned chickpea",
      "canned green beans",
      "canned lentils",
      "canned peas",
      "cannellini beans",
      "chana",
      "chana dal",
      "chickpea",
      "cluster beans",
      "edamame",
      "fava beans",
      "fermented black beans",
      "field peas",
      "flageolets",
      "gigantes",
      "golden wax beans",
      "green beans",
      "green lentils",
      "honey beans",
      "horse gram",
      "hyacinth beans",
      "kala chana",
      "kidney beans",
      "lentils",
      "lima beans",
      "mayocoba beans",
      "mixed beans",
      "moth beans",
      "mung beans",
      "natto",
      "peas",
      "petai",
      "pigeon peas",
      "pink beans",
      "pinto beans",
      "pork and beans",
      "ranch-style beans",
      "red beans",
      "red lentils",
      "refried beans",
      "snake beans",
      "snap peas",
      "snow peas",
      "soy flakes",
      "soybeans",
      "split peas",
      "string beans",
      "urad dal",
      "wax beans",
      "white beans",
      "white pea",
      "winged beans",
      "yellow lentils",
      "yellow split peas",
    ],
  },
  pasta:{
    // name: "Pasta",
    values: [
      "acini di pepe",
      "angel hair pasta",
      "banh pho",
      "beef ravioli",
      "beef tortellini",
      "black bean pasta",
      "bow-tie pasta",
      "brown rice pasta",
      "bucatini",
      "busiate",
      "cannelloni",
      "cavatelli",
      "ditalini",
      "fettuccine",
      "fideo pasta",
      "fregola",
      "frozen dumplings",
      "glass noodles",
      "gluten-free pasta",
      "gnocchi",
      "hilopites",
      "israeli couscous",
      "kelp noodles",
      "kluski noodles",
      "lasagna",
      "linguine",
      "lobster ravioli",
      "mac 'n cheese",
      "macaroni",
      "mafalde",
      "manicotti",
      "matzo farfel",
      "misua",
      "naengmyeon noodles",
      "noodle",
      "orecchiette",
      "orzo",
      "paccheri",
      "palmini",
      "pappardelle",
      "pasta salad mix",
      "pasta shell",
      "pearl couscous",
      "penne",
      "penne rigate",
      "pierogi",
      "pot pie noodle",
      "quinoa pasta",
      "radiatore",
      "ramen noodles",
      "ravioli",
      "rice noodles",
      "rice vermicelli",
      "rice-a-roni",
      "rigatoni",
      "rotelle",
      "shirataki noodles",
      "soba noodles",
      "somen noodles",
      "soup pasta",
      "spaetzle",
      "spaghetti",
      "spiral pasta",
      "sweet potato noodles",
      "tagliatelle",
      "thai rice noodles",
      "tortellini",
      "trahanas",
      "trottole",
      "udon noodles",
      "vermicelli",
      "yakisoba noodles",
      "yolk-free noodles",
      "ziti",
    ],
  },
  bread_and_salty_snacks:{
    // name: "Bread & Salty Snacks",
    values: [
      "arabic bread",
      "bagel",
      "baguette",
      "bao bun",
      "boboli",
      "bread",
      "bread crumbs",
      "breading mix",
      "breadsticks",
      "brioche",
      "cauliflower wraps",
      "challah",
      "chapati",
      "cheetos",
      "chocolate muffin",
      "coconut wraps",
      "corn chips",
      "corn nuts",
      "corn tortillas",
      "cornbread",
      "crackers",
      "crispbread",
      "croissants",
      "crostini",
      "croutons",
      "crumpet",
      "dry-roasted peanuts",
      "english muffin",
      "ezekiel bread",
      "flatbread",
      "flour tortillas",
      "focaccia",
      "french fries",
      "fruit bread",
      "garlic bread",
      "gluten free bread",
      "gluten-free bread crumbs",
      "hashbrowns",
      "hawaiian rolls",
      "injera",
      "italian bread crumbs",
      "japanese peanuts",
      "keto bread",
      "matzo",
      "milk bread",
      "muffin",
      "naan",
      "oven chips",
      "panettone",
      "papad",
      "papdi",
      "pav bun",
      "pita",
      "pita chips",
      "popcorn",
      "popcorn shrimp",
      "poppadoms",
      "pork rinds",
      "potato bread",
      "potato chips",
      "potato waffle",
      "pretzels",
      "pumpernickel",
      "rice cake",
      "roasted chickpeas",
      "roasted gram",
      "rotis",
      "rusks",
      "rustic italian bread",
      "rye bread",
      "seed bread",
      "sourdough bread",
      "spinach wraps",
      "sprouted grain bread",
      "stuffing mix",
      "sweet bread",
      "sweet potato fries",
      "taco kit",
      "taco shells",
      "tartlet shells",
      "tater tots",
      "tawa parathas",
      "tortilla chips",
      "tostada shells",
      "unpopped popcorn",
      "vegetable chips",
      "waffle fries",
      "wasabi peas",
      "whole-wheat wraps",
      "yeast extract spread",
      "yorkshire pudding",
      "zwieback",
    ],
  },
  oils_and_fats:{
    // name: "Oils & Fats",
    values: [
      "almond oil",
      "argan oil",
      "avocado oil",
      "bacon grease",
      "basil oil",
      "beef dripping",
      "beef fat",
      "black seed oil",
      "butter-flavored cooking spray",
      "cacao butter",
      "camelina oil",
      "cannabutter",
      "canola oil",
      "castor oil",
      "chicken drippings",
      "chili oil",
      "cinnamon oil",
      "coconut oil",
      "cooking spray",
      "corn oil",
      "duck fat",
      "essential oil",
      "extra virgin olive oil",
      "flaxseed oil",
      "frying oil",
      "garlic oil",
      "goose fat",
      "grapeseed oil",
      "hazelnut oil",
      "hemp seed oil",
      "jojoba oil",
      "lamb fat",
      "lard",
      "lavender oil",
      "lemon oil",
      "macadamia oil",
      "marula oil",
      "medium-chain triglyceride",
      "moringa seed oil",
      "mustard oil",
      "olive oil",
      "orange essential oil",
      "oregano oil",
      "palm oil",
      "peanut oil",
      "pecan oil",
      "peppermint oil",
      "pistachio oil",
      "pork dripping",
      "pork fat",
      "pumpkin seed oil",
      "red palm oil",
      "rice bran oil",
      "rose oil",
      "rosehip seed oil",
      "safflower oil",
      "salad oil",
      "schmaltz",
      "sesame oil",
      "shortening",
      "soybean oil",
      "sunflower oil",
      "sushi vinegar",
      "tallow",
      "truffle oil",
      "vegetable oil",
      "virgin coconut oil",
      "walnut oil",
      "wheat germ oil",
      "white cacao butter",
      "white truffle oil",
    ],
  },
  dressings_and_vinegars:{
    // name: "Dressings & Vinegars",
    values: [
      "apple cider vinegar",
      "avocado oil mayonnaise",
      "balsamic dressing",
      "balsamic vinaigrette",
      "balsamic vinegar",
      "black vinegar",
      "blue cheese dressing",
      "caesar dressing",
      "cane vinegar",
      "canola mayonnaise",
      "catalina dressing",
      "champagne vinaigrette",
      "champagne vinegar",
      "chipotle mayonnaise",
      "cilantro dressing",
      "coconut vinegar",
      "creamy dressing",
      "french dressing",
      "greek vinaigrette",
      "green goddess dressing",
      "honey mustard dressing",
      "honey vinegar",
      "italian dressing",
      "japanese mayonnaise",
      "malt vinegar",
      "mayonnaise",
      "miso dressing",
      "poppyseed dressing",
      "ranch dressing",
      "raspberry vinaigrette",
      "raspberry vinegar",
      "red wine vinaigrette",
      "red wine vinegar",
      "rice wine vinegar",
      "russian dressing",
      "sesame dressing",
      "sherry vinegar",
      "spicy peanut dressing",
      "spicy vinegar",
      "sun-dried tomato vinaigrette",
      "sweet chilli sauce",
      "tarragon vinegar",
      "thousand island",
      "verjuice",
      "vinaigrette dressing",
      "vinegar",
      "white balsamic vinegar",
      "white wine vinegar",
    ],
  },
  condiments_and_relishes:{
    // name: "Condiments & Relishes",
    values: [
      "aji amarillo",
      "applesauce",
      "bacon jam",
      "bbq sauce",
      "bonito flakes",
      "bread & butter pickles",
      "brown mustard",
      "buffalo sauce",
      "capers",
      "chamoy",
      "chermoula",
      "chili crisp",
      "chili jam",
      "chili paste",
      "chili sauce",
      "chili-garlic sauce",
      "chipotle in adobo",
      "chutney",
      "corn relish",
      "cranberry sauce",
      "crispy onions",
      "dark soy sauce",
      "diced green chiles",
      "dijon mustard",
      "dill relish",
      "doubanjiang",
      "duck sauce",
      "english mustard",
      "fire-roasted green chiles",
      "fish sauce",
      "german mustard",
      "giardiniera",
      "ginger chili paste",
      "ginger paste",
      "ginger-garlic paste",
      "gochujang",
      "green chilli paste",
      "green chilli sauce",
      "green chutney",
      "harissa",
      "honey mustard",
      "horseradish mustard",
      "hot pepper jelly",
      "hot sauce",
      "hp sauce",
      "jalapeno jelly",
      "karashi",
      "kecap manis",
      "ketchup",
      "kimchi",
      "korean bbq sauce",
      "lime pickle",
      "liquid aminos",
      "maggi sauce",
      "mango chutney",
      "mint jelly",
      "mustard",
      "okra pickles",
      "onion jam",
      "oyster sauce",
      "pepper paste",
      "peri peri",
      "picante sauce",
      "pickle",
      "pickled beets",
      "pickled ginger",
      "pickled jalapenos",
      "pickled onions",
      "pickled red onion",
      "pickled vegetables",
      "pickling juice",
      "pomegranate molasses",
      "ponzu",
      "preserved lemon",
      "red pepper jelly",
      "red pepper relish",
      "sambal",
      "sambal oelek",
      "shrimp paste",
      "soy sauce",
      "sriracha",
      "sun dried tomato",
      "sweet and sour sauce",
      "sweet pickle relish",
      "tamari",
      "tamarind chutney",
      "tamarind paste",
      "tartar sauce",
      "teriyaki sauce",
      "thai chili paste",
      "thai sweet chili sauce",
      "tomato in oil",
      "tomato relish",
      "tonkatsu sauce",
      "ume plum vinegar",
      "wasabi",
      "wholegrain mustard",
      "worcestershire",
      "yuzu juice",
      "yuzu kosho",
    ],
  },
  sauces_spreads_and_dips:{
    // name: "Sauces, Spreads & Dips",
    values: [
      "adjika",
      "aioli sauce",
      "alfredo sauce",
      "anchovy paste",
      "artichoke dip",
      "balsamic glaze",
      "bean dip",
      "beef gravy",
      "black bean and corn salsa",
      "black bean sauce",
      "black pepper sauce",
      "bolognese sauce",
      "browning sauce",
      "butter chicken sauce",
      "béarnaise sauce",
      "béchamel sauce",
      "char siu sauce",
      "cheese dip",
      "chicken gravy",
      "chimichurri sauce",
      "chunky peanut butter",
      "cocktail sauce",
      "country gravy",
      "curry sauce",
      "demi-glace",
      "deviled ham spread",
      "duck liver pâté",
      "eggplant dip",
      "enchilada sauce",
      "fire-roasted tomato",
      "french onion dip",
      "garlic butter",
      "garlic spread",
      "general tso sauce",
      "green olive tapenade",
      "guacamole",
      "hoisin sauce",
      "hollandaise sauce",
      "horseradish sauce",
      "hummus",
      "jalapeño cream sauce",
      "japanese curry",
      "jerk sauce",
      "kung pao sauce",
      "liver spread",
      "mango salsa",
      "marinara sauce",
      "mentsuyu",
      "mole paste",
      "mushroom gravy",
      "nuoc cham",
      "olive paste",
      "onion gravy",
      "orange sauce",
      "pad thai sauce",
      "pasta sauce",
      "peach salsa",
      "peanut butter",
      "pesto",
      "pico de gallo",
      "pimento cheese spread",
      "pineapple salsa",
      "plum sauce",
      "pomegranate sauce",
      "pork gravy",
      "queso dip",
      "red enchilada sauce",
      "salsa",
      "salsa verde",
      "sausage gravy",
      "schezwan sauce",
      "sesame sauce",
      "sloppy joe sauce",
      "sofrito",
      "spinach dip",
      "ssamjang",
      "steak sauce",
      "stewed tomatoes",
      "stir-fry sauce",
      "sun-dried tomato paste",
      "sun-dried tomato pesto",
      "sweet tamarind sauce",
      "taco sauce",
      "tahini",
      "tapenade",
      "tarama",
      "tempura dip",
      "thai peanut sauce",
      "tikka masala sauce",
      "tomato paste",
      "tomato sauce",
      "tomato tapenade",
      "tomatoes with green chiles",
      "turkey gravy",
      "tzatziki",
      "vodka sauce",
      "white clam sauce",
      "white pizza sauce",
      "xo sauce",
      "yum yum sauce",
    ],
  },
  soups_stews_stocks:{
    // name: "Soups, Stews & Stocks",
    values: [
      "bean soup mix",
      "bean with bacon soup",
      "beef broth",
      "beef consommé",
      "beef stock",
      "beef stock paste",
      "better than bouillon",
      "bone broth",
      "bouillon cube",
      "bovril",
      "broccoli-cheese soup",
      "butternut squash soup",
      "callaloo",
      "cheese soup",
      "chicken bone broth",
      "chicken broth",
      "chicken consommé",
      "chicken noodle soup",
      "chicken rice soup",
      "chicken soup",
      "chicken stock",
      "chicken stock paste",
      "chili beans",
      "chili without beans",
      "clam chowder",
      "clam juice",
      "condensed chicken gumbo soup",
      "cream of asparagus",
      "cream of bacon",
      "cream of broccoli",
      "cream of cauliflower",
      "cream of celery",
      "cream of chicken",
      "cream of mushroom",
      "cream of potato",
      "cream of shrimp soup",
      "creamed corn",
      "dashi",
      "dashida",
      "duck stock",
      "fish stock",
      "golden mushroom soup",
      "green pea soup",
      "ham stock",
      "lamb broth",
      "lamb stock",
      "lentil soup",
      "lobster bisque",
      "lobster stock",
      "minestrone",
      "miso soup",
      "mushroom broth",
      "nacho cheese soup",
      "onion soup",
      "onion soup mix",
      "oxtail soup",
      "pork & beans",
      "pork broth",
      "pork stock",
      "potato soup mix",
      "ramen noodle soup",
      "seafood stock",
      "shrimp stock",
      "split pea soup",
      "stock paste",
      "sun-dried tomato bisque mix",
      "thai chicken broth",
      "tomato bisque",
      "tomato soup",
      "tortilla soup base",
      "turkey broth",
      "turkey stock",
      "veal broth",
      "veal stock",
      "vegetable beef soup",
      "vegetable broth",
      "vegetable soup",
      "vegetable soup mix",
      "venison stock",
    ],
  },
  desserts_and_sweet_snacks:{
    // name: "Desserts & Sweet Snacks",
    values: [
      "almond cookies",
      "apple butter",
      "apple chips",
      "apple jelly",
      "apricot jam",
      "baking chocolate",
      "banana chips",
      "banana pudding",
      "biscuits",
      "blackberry preserves",
      "blueberry jam",
      "butter cookies",
      "butterscotch",
      "butterscotch chips",
      "butterscotch pudding",
      "candied cherry",
      "candied fruit",
      "candied ginger",
      "candied peel",
      "candy",
      "candy cane",
      "candy corn",
      "caramel candies",
      "caramel sauce",
      "cherry jam",
      "chocolate",
      "chocolate candy",
      "chocolate chip cookies",
      "chocolate chips",
      "chocolate cookies",
      "chocolate egg",
      "chocolate fudge",
      "chocolate hazelnut spread",
      "chocolate peanut butter",
      "chocolate powder",
      "chocolate pudding",
      "chocolate spread",
      "chocolate wafer",
      "chocolate-covered espresso bean",
      "cinnamon candy",
      "cinnamon chips",
      "cinnamon roll",
      "cocoa",
      "cookie butter",
      "cookies",
      "cotton candy",
      "couverture chocolate",
      "currant jelly",
      "dark chocolate",
      "dark chocolate chips",
      "dark chocolate cocoa powder",
      "dark couverture chocolate",
      "digestive biscuits",
      "doughnut",
      "dried mixed fruit",
      "fig jam",
      "fudge",
      "ginger snaps",
      "graham cracker",
      "grape jelly",
      "guava paste",
      "gummies",
      "hershey kisses",
      "ice-cream cone",
      "instant lemon pudding",
      "instant pudding",
      "jam",
      "lady fingers",
      "lemon jelly",
      "licorice",
      "marmalade",
      "marshmallow",
      "marshmallow creme",
      "orange marmalade",
      "oreo",
      "peach preserves",
      "peanut butter chips",
      "peanut butter cups",
      "peppermint candy",
      "pineapple jam",
      "pistachio instant pudding",
      "plum jam",
      "praline",
      "pumpkin butter",
      "raspberry jam",
      "raspberry sauce",
      "raspberry sorbet",
      "sorbet",
      "sponge cake",
      "strawberry jam",
      "strawberry puree",
      "strawberry sauce",
      "sugar cookies",
      "toffee bits",
      "vanilla pudding",
      "wafer",
      "waffles",
      "white chocolate",
      "white chocolate chip",
      "white couverture",
    ],
  },
  wine_beer_and_spirits:{
    // name: "Wine, Beer & Spirits",
    values: [
      "absinthe",
      "alchermes",
      "aloe liqueur",
      "amaretto",
      "amarula cream",
      "anisette",
      "aperol",
      "apple brandy",
      "apple liqueur",
      "apricot brandy",
      "arak",
      "armagnac",
      "banana liqueur",
      "beer",
      "birch beer",
      "bitters",
      "bloody mary mix",
      "bourbon",
      "brandy",
      "bénédictine",
      "cachaça",
      "caramel liqueur",
      "champagne",
      "chocolate bitters",
      "chocolate liqueur",
      "cider",
      "cinnamon alcohol",
      "coconut liqueur",
      "coconut rum",
      "coffee liqueur",
      "cognac",
      "cooking wine",
      "cream sherry",
      "creme de cacao",
      "creme de cassis",
      "creme de menthe",
      "curacao",
      "daiquiri mix",
      "dessert wine",
      "drambuie",
      "dry vermouth",
      "egg liqueur",
      "elderflower liqueur",
      "galliano",
      "gin",
      "ginger liqueur",
      "ginger wine",
      "gold rum",
      "grapefruit bitters",
      "grappa",
      "hazelnut liqueur",
      "herbal liqueur",
      "irish cream",
      "kirsch",
      "limoncello",
      "liqueur",
      "madeira wine",
      "maraschino",
      "marsala wine",
      "melon liqueur",
      "mezcal",
      "mirin",
      "mojito mix",
      "moonshine",
      "orange liqueur",
      "peach liqueur",
      "pear liqueur",
      "pisang ambon",
      "plum brandy",
      "plum wine",
      "port wine",
      "raspberry liqueur",
      "red cooking wine",
      "red wine",
      "rhum agricole",
      "rice wine",
      "root beer schnapps",
      "rosé wine",
      "rum",
      "rumchata liqueur",
      "sake",
      "sangria",
      "schnapps",
      "shaoxing wine",
      "sherry",
      "sloe gin",
      "soju",
      "sparkling wine",
      "strawberry liqueur",
      "tequila",
      "vanilla liqueur",
      "vermouth",
      "violette liqueur",
      "vodka",
      "watermelon liqueur",
      "whisky",
      "white chocolate liqueur",
      "white cooking wine",
      "white port",
      "white wine",
    ],
  },
  beverages:{
    // name: "Beverages",
    values: [
      "acai berry juice",
      "aloe vera juice",
      "apple juice",
      "apple tea",
      "apricot juice",
      "banana juice",
      "beet juice",
      "berry juice",
      "blackcurrant juice",
      "blueberry juice",
      "butterfly pea flower",
      "carrot juice",
      "chai tea",
      "cherry juice",
      "chicory coffee",
      "chicory powder",
      "clamato",
      "club soda",
      "coconut water",
      "coffee",
      "coke",
      "cordial",
      "cranberry juice",
      "cream of coconut",
      "cream soda",
      "decaf coffee",
      "dr pepper",
      "drinking chocolate",
      "eggnog",
      "elderflower cordial",
      "energy drink",
      "espresso",
      "fruit juice",
      "ginger ale",
      "ginger beer",
      "golden milk powder",
      "grape juice",
      "grape soda",
      "grapefruit juice",
      "grapefruit soda",
      "green tea",
      "green tea leaves",
      "guava juice",
      "herbal tea",
      "horchata",
      "iced coffee concentrate",
      "iced tea",
      "iced tea mix",
      "jasmine tea",
      "juice blend",
      "kombucha",
      "kool aid",
      "kvass",
      "lapsang souchong",
      "lemon crystals",
      "lemon lime soda",
      "lemonade",
      "lemonade concentrate",
      "lemonade mix",
      "lime cordial",
      "lime soda",
      "limeade concentrate",
      "litchi juice",
      "malted milk powder",
      "mango juice",
      "margarita mix",
      "mead",
      "mountain dew",
      "orange juice",
      "orange soda",
      "passion-fruit juice",
      "peach juice",
      "peach soda",
      "pear juice",
      "peppermint tea",
      "pepsi",
      "pineapple juice",
      "pineapple soda",
      "pomegranate juice",
      "prune juice",
      "raspberry juice",
      "rooibos tea",
      "root beer",
      "rose syrup",
      "sprite",
      "strawberry drink mix",
      "strawberry juice",
      "strawberry milkshake powder",
      "strawberry soda",
      "tamarind juice",
      "tangerine juice",
      "tea",
      "tea leaves",
      "tomato juice",
      "tonic water",
      "turmeric juice",
      "v8 juice",
      "watermelon juice",
      "white grape juice",
      "white tea",
    ],
  },
};
const profilePreferenceItems = {
  // {
  //   name: "Dietary Preferences",
  //   inputType: "checkbox",
  //   values: [
  //     "Balanced",
  //     "High-Fiber",
  //     "High-Protein",
  //     "Low-Carb",
  //     "Low-Fat",
  //     "Low-Sodium",
  //   ],
  // },
  diet_preferences :{
    // name: "Diet Preferences",
    inputType: "checkbox",
    values: [
      "Gluten-Free",
      "Ketogenic",
      "Vegetarian",
      "Lacto-Vegetarian",
      "Ovo-Vegetarian",
      "Vegan",
      "Pescetarian",
      "Paleo",
      "Primal",
      "Low FODMAP",
      "Whole30",
    ],
  },
  intolerances: {
    // name: "Intolerances",
    inputType: "checkbox",
    values: [
      "Dairy",
      "Egg",
      "Gluten",
      "Grain",
      "Peanut",
      "Seafood",
      "Sesame",
      "Shellfish",
      "Soy",
      "Sulfite",
      "Tree Nut",
      "Wheat",
    ],
  },
  // {
  //   name: "Health Preferences",
  //   inputType: "checkbox",
  //   values: [
  //     "Alcohol-Cocktail",
  //     "Alcohol-Free",
  //     "Celery-Free",
  //     "Crustacean-Free",
  //     "Dairy-Free",
  //     "DASH",
  //     "Egg-Free",
  //     "Fish-Free",
  //     "FODMAP-Free",
  //     "Gluten-Free",
  //     "Immuno-Supportive",
  //     "Keto-Friendly",
  //     "Kidney-Friendly",
  //     "Kosher",
  //     "Low Potassium",
  //     "Low Sugar",
  //     "Lupine Free",
  //     "Mediterranean",
  //     "Mollusk-Free",
  //     "Mustard Free",
  //     "No oil added",
  //     "Paleo",
  //     "Peanut-Free",
  //     "Pescatarian",
  //     "Pork-Free",
  //     "Red-Meat-Free",
  //     "Sesame Free",
  //     "Shellfish Free",
  //     "Soy Free",
  //     "Sugar-Conscious",
  //     "Sulfite-Free",
  //     "Tree-Nut Free",
  //     "Vegan",
  //     "Vegetarian",
  //     "Wheat Free",
  //   ],
  // },
  // {
  //   name: "Dietary Restrictions",
  //   inputType: "checkbox",
  //   values: [
  //     "Gluten Free",
  //     "Low Sugar",
  //     "Nut Free",
  //     "Vegan",
  //     "Low Sodium",
  //     "Vegetarian",
  //     "Low Carb",
  //     "Dairy",
  //   ],
  // },
  physical: {
    // name: "Physical",
    inputType: "number",
    values: ["Height-F", "Height-I", "Weight", "Age", "Gender"],
  },
  body_type: {
    // name: "Body Type",
    inputType: "radio",
    values: ["Thin", "Average", "Athletic", "Over Weight", "Under Weight"],
  },
  body_class: {
    // name: "Body Class",
    inputType: "radio",
    values: ["Long and Lean", "Long and Muscular", "Stocky and Muscular"],
  },
  // {
  //   name: "Preferred Diet Philosophy",
  //   inputType: "radio",
  //   values: [
  //     "Keto",
  //     "Vegan",
  //     "Low Carb",
  //     "South Beach",
  //     "Atkins",
  //     "Paleo",
  //     "Vegetarian",
  //     "Weight Watchers",
  //   ],
  // },
  cuisine_preferences:{
    // name: "Cuisine Preferences",
    inputType: "checkbox",
    values: [
      "African",
      "American",
      // "Asian",
      "British",
      "Cajun",
      "Caribbean",
      // "Central Europe",
      "Chinese",
      "Eastern Europe",
      "European",
      "French",
      "German",
      "Greek",
      "Indian",
      "Irish",
      "Italian",
      "Japanese",
      "Jewish",
      "Korean",
      // "Kosher",
      "Latin American",
      "Mediterranean",
      "Mexican",
      "Middle Eastern",
      "Nordic",
      // "South American",
      "Southern",
      // "South East Asian",
      "Spanish",
      "Thai",
      "Vietnamese",
      // "World"
    ],
  },
  medical_conditions:{
    // name: "Medical Conditions",
    inputType: "checkbox",
    values: [
      "Diabetes",
      "Cardio Vascular",
      "Hypertension",
      "Osteoporosis",
      "High Cholesterol",
      "Arthritis",
      "High Triglycerides",
      "Gastritis",
    ],
  },
  physical_activities:{
    // name: "Physical Activities",
    inputType: "checkbox",
    values: [
      "Lift Weights",
      "Yoga",
      "Play Sports",
      "Cardio Classes",
      "Running",
      "Cross Fit",
      "Walking",
      "Bicycling",
    ],
  },
  objectives:{
    // name: "Objectives",
    inputType: "checkbox",
    values: [
      "Lose weight",
      "Cardio health",
      "Gain muscle",
      "Blood pressure control",
      "Get into shape",
      "Address health issue",
      "Increase circulation",
      "Eat healthier",
    ],
  },
  primary_use:{
    // name: "Primary Use",
    inputType: "checkbox",
    values: [
      "Meal planning",
      "Batch cooking",
      "Recipe suggestion",
      "Shopping List",
      "Inventory control",
      "Learning to cook",
      "Meal prepping",
      "Nutritional information",
    ],
  },
  grocery_shopping_frequency:{
    // name: "Grocery Shopping Frequency",
    inputType: "radio",
    values: ["1 time a month", "2 times a month", "1 time a week"],
  },
  meals_per_day:{
    // name: "Meals in a day",
    inputType: "radio",
    values: ["1 meal", "2 meals", "3 meals"],
  },
};

const profileDescriptionItems = [
  {
    title: "Tell us about your diet and preferences",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit",
  },
  {
    title: "Initial Pantry Setup",
    description:
      "In this step we want you to tell us what is in your pan, in order to give us an idea of the dishes with great nutritional value that we have to offer you, depending on your preferences.",
    note: "*We know that this step can be a bit long, that's why you can always save the process and continue later.",
  },
];

module.exports = {
  profilePreferenceItems,
  profileTabItems,
  profileDescriptionItems,
};
