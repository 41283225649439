import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { userActions } from "../store/userSlice";
import AuthLoader from "./AuthLoader";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

export default function LoggedInCheck() {
  const { uid } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  console.log("auth loading", loading);
  onAuthStateChanged(auth, async (user) => {
    try {
      if (user) {
        console.log("user", user);
        if (!uid) {
          const res = await getDoc(doc(db, "userPreferences", user.uid));
          // let fullname = "";
          // if (user.displayName) {
          //   fullname = user.displayName;
          // } else {
          //   fullname = localStorage.getItem("displayName");
          // }
          // console.log("full", fullname)
          // console.log("stacked recipe", stackedRecipe, fetchedRecipes.data)
          dispatch(
            userActions.setCurrentUser({
              displayName: user.displayName,
              email: user.email,
              uid: user.uid,
              photoURL: user.photoURL,
              preferences: res.data(),
            })
          );
        }
        if (user?.isNewUser) {
          // google anaylytics signup trigger
          window.dataLayer.push({
            event: "signup",
          });
        }
      }
    } catch (e) {
      console.log("error while checking logged in", e);
    } finally {
      setLoading(false);
    }
  });
  if (loading) {
    return <AuthLoader />;
  } else {
    if (uid?.length > 0) return <Navigate to="/dashboard" />;
    else return <Navigate to="/login" />;
  }
}
