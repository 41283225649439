import React, { useRef, useState } from "react";
import styled from "styled-components";
import LoadingButton from "../LoadingButton";
import { Link } from "react-router-dom";
import leaf4 from "../../assets/Login-Signup/leaf-tomato1.webp";
import leaf6 from "../../assets/landing-page/tomato.png";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../authentication/firebase";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alertSlice";
// import useAddStackedRecipe from "../../hooks/useAddStackedRecipe";
import CloseIcon from "@mui/icons-material/Close";
import BackdropWrapper from "./BackdropWrapper";
import Notification from "./Notification";
import { modalActions } from "../../store/modalSlice";
import SignInWithoutEmail from "../../authentication/SignInWithoutEmail";

export default function LoginModal() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [loading, setLoading] = useState(false);
  const [verifyNotification, setVerifyNotification] = useState(false);

  const dispatch = useDispatch();

  // const addStackedRecipe = useAddStackedRecipe();

  // let { from } = location.state || { from: { pathname: "/dashboard/explore" } };

  function closeLoginModal() {
    dispatch(modalActions.setLoginModalActive(false));
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await signInWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
      );

      console.log("response", response.user);

      if (response.user.emailVerified) {
        closeLoginModal();
      } else {
        console.log("email not verified");
        dispatch(
          alertActions.setAlert({
            title: "Email not verified",
            messageType: "warning",
          })
        );
        verifyNotificationHandler(true);
      }
    } catch (e) {
      console.log("Failed to login", e, JSON.parse(JSON.stringify(e)).code);
      dispatch(
        alertActions.setAlert({
          title: JSON.parse(JSON.stringify(e)).code,
          messageType: "warning",
        })
      );
    }
    setLoading(false);
  };

  const verifyNotificationHandler = () => {
    setVerifyNotification((current) => {
      return !current;
    });
  };

  return (
    <>
      <BackdropWrapper
        element={
          <Notification
            heading="Email Verification"
            msg="Email verification link has been sent to your email, please verify and login."
            backdropHandler={verifyNotificationHandler}
          />
        }
        open={verifyNotification}
        smallSize={true}
      />
      <StyledLoginModal>
        <div className="heading">
          <h4>Login</h4>
          <button className="icon" onClick={closeLoginModal}>
            {<CloseIcon htmlColor="#F79C16" fontSize="large" />}
          </button>
        </div>

        <div className="text">
          <img className="img4" src={leaf4} alt="leaf" />
          <img className="img6" src={leaf6} alt="leaf" />
          <h5 className="highlight">Login to save changes</h5>
          <form onSubmit={submitHandler}>
            <div>
              <input
                ref={emailRef}
                placeholder="Email"
                name="email"
                type="email"
                required
              />
            </div>
            <div>
              <input
                placeholder="Password"
                name="password"
                type="password"
                required
                ref={passwordRef}
                pattern=".{8,}"
                title="Password must be at least 8 characters long"
              />
            </div>

            <div className="btns">
              <LoadingButton loading={loading} type="submit" title="Log In" />
              <Link to="/login" className="forgot-pass" onClick={() => closeLoginModal()}>
                Forgot password?
              </Link>
              <div>
                <span>Don't have an account?</span>
                <Link to="/signup" onClick={() => closeLoginModal()}>
                  Sign Up
                </Link>
              </div>
            </div>
          </form>
          <div className="space">
            <span>Or</span>
            <div />
          </div>
          <SignInWithoutEmail />
        </div>
      </StyledLoginModal>
    </>
  );
}

const StyledLoginModal = styled.div`
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  h2 {
    line-height: 0.8;
    cursor: pointer;
  }
  h3 {
    color: var(--primary-color);
    font-weight: bolder;
  }
  .highlight {
    color: var(--secondary-color);
  }
  .text {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5px;
    margin-left: auto;
    .img4,
    .img6 {
      position: absolute;
      z-index: -1;
    }
    .img6 {
      top: 65%;
      left: 10%;
      width: 50px;
    }
    .img4 {
      top: 40%;
      right: 0%;
      width: 70px;
    }
    h1 {
      margin-top: 20px;
      color: var(--primary-color);
    }
    form {
      /* width: 40%; */
      a {
        color: var(--secondary-color);
      }
      input {
        height: 50px;
        min-width: 240px;
        width: 100%;
      }
      div {
        margin: 18px auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        label {
          float: left;
          margin-bottom: 4px;
        }
      }
      .btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        button {
          background-color: var(--accent-color);
          &:hover {
            background-color: var(--accent-dark-color);
          }
        }
        div {
          margin: 0;
          align-items: center;
          flex-direction: row;
          gap: 4px;
        }
        a {
          text-decoration: underline;
        }
        .forgot-pass {
          color: var(--text-color);
        }
      }
    }
    .space {
      position: relative;
      width: 38%;
      div {
        width: 100%;
        background-color: var(--text-light-color);
        height: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: -1;
      }
      span {
        background-color: white;
        z-index: 10;
        padding: 0 20px;
      }
    }
    .google-button {
      color: black;
      background-color: transparent;
      border: 1px solid var(--text-light-color);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
`;
