import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";

function ProfileTabs({ editable, tabData }) {
  const handleClick = (e) => {
    if (editable !== false) {
      if (e.target.classList.contains("active")) {
        e.target.classList.remove("active");
      } else {
        e.target.classList.add("active");
      }
    }
  };
  return (
    <StyledProfileTabs editable={editable}>
      {Object.entries(tabData).map(([key, value], index) => (
        <Accordion key={key}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                style={{ color: "#F9B147", width: "38px", height: "38px" }}
              />
            }
          >
            <h4>
              {key
                ?.split("_")
                .map(
                  (name) => name.charAt(0).toUpperCase() + name.slice(1) + " "
                )}
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className="items-div">
              {key === "physical"
                ? Object.entries(...value.values).map(([key, value]) => {
                    return (
                      <p
                        key={key}
                      >
                        {`${key} = ${value}`}
                      </p>
                    );
                  })
                : value.values.map((key, index) => (
                    <p
                      key={key}
                    >
                      {key}
                    </p>
                  ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </StyledProfileTabs>
  );
}

const StyledProfileTabs = styled.div`
  width: var(--laptop-width);
  margin: auto;
  h4 {
    color: var(--secondary-color);
  }
  .items-div {
    p {
      margin: 4px;
      padding: 10px 4px;
      font-size: 16px;
      display: inline-block;
      white-space: nowrap;
      border: 1px solid #dddddd;
      width: fit-content;
      border-radius: 4px;
      ${(props) =>
        props.editable &&
        "&:hover {cursor: pointer;background-color: var(--green-light);color: white;}"}
    }
    .active {
      background-color: var(--green-color);
      color: white;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export default ProfileTabs;
