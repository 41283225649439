import { onAuthStateChanged } from "firebase/auth";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../store/userSlice";
import AuthLoader from "./AuthLoader";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

function PrivateRoute({ children }) {
  const { uid, preferences } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      try {
        if (!uid) {
          const res = await getDoc(doc(db, "userPreferences", user.uid));
          // let fullname = "";
          // if (user.displayName) {
          //   fullname = user.displayName;
          // } else {
          //   fullname = localStorage.getItem("displayName");
          // }
          // console.log("full", fullname)
          // console.log("stacked recipe", stackedRecipe, fetchedRecipes.data)
          dispatch(
            userActions.setCurrentUser({
              displayName: user.displayName,
              email: user.email,
              uid: user.uid,
              photoURL: user.photoURL,
              preferences: res.data(),
            })
          );
          if (user?.isNewUser) {
            // google anaylytics signup trigger
            window.dataLayer.push({
              event: "signup",
            });
          }
        }
      } catch (e) {
        console.log("e", e);
      }
    }
  });
  if (loading) {
    return <AuthLoader />;
  } else {
    // if (!uid.length > 0) {
    //   return <Navigate to="/login" state={{ from: location }} />;
    // }
    return children;
  }
}

export default PrivateRoute;
