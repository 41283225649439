import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "../authentication/firebase";

export default async function fixGeneratedRecipes() {
    try{
    const q = query(
        collection(db, "gptRecipes"),
        where("generatedFromScript", "==", true),
        orderBy("generationDate", "desc"),
        limit(79)
      );

      const docsRef = await getDocs(q);
      let fetchedRecipes = [];
      docsRef.forEach((doc) => fetchedRecipes.push(doc.data().extendedIngredients.length));
      console.log("fetch", fetchedRecipes);
    }catch(e) {
        console.log("error while fetching recipes", e)
    }
}