import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Configuration, OpenAIApi } from "openai";
import LoadingButton from "../LoadingButton";
import { useRef } from "react";
import RecipeCard from "../recipes/RecipeCard";

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);

const convertToRecipesFormat = `{
  title: name of the recipe,
  preparationMinutes: Number,
  healthScore: Int or float ranges from 1 to 100,
  readyInMinutes: Number,
  servings: Number,
  image: "leave empty",
  summary: "generate description of the recipe, around 500 words. Don't leave this field empty. Be creative",
  healthBenefitsShort: ["concise health benefits list of ingredients used"],
  alternativeRecipes: ["add list of alternatives recipes"],
  dishTypes: [
    "array of values that could be breakfast, lunch, dinner, snack or more",
  ],
  dietCategory: [
    "include diets the recipe follows"
  ],
  steps: [include steps here, array of strings not objects],
  sustainable: Boolean,
  cookingMinutes: Number,
  healthBenefitsLong: [detailed health benefits list of ingredients used],
  healthyAlternativeRecipes: [
    add list of alternatives recipes that are healthier
  ],
  cuisines: [array of the cuisines a recipe fall into],
  dietaryRestrictions: [include dietary restrictions the recipe has],
  extendedIngredients: [
    {
      aisle:
        String(aisle to which the ingredient belongs to(use standard US aisle names for a grocery store)),
      image: "",
      consistency: String(solid, liquid or anything else),
      nameClean:
        String(the adjective with the ingredient name should be after the name),
      original: String(include the description of ingredient here),
      amount: Number(int or float)(ingredient's amount),
      unit: String(ingredient's amount unit),
      measures: {
        us: {
          amount: Number(int or float)(ingredient's amount),
          unitShort: String(short unit),
          unitLong: String(long unit),
        },
        metric: {
          amount: Number(int or float)(ingredient's amount),
          unitShort: String(short unit),
          unitLong: String(long unit),
        },
      },
    },
  ],
  carbonFootprint: String(describe the carbon footprint of the recipes here in few lines. Compare the carbon footprint of the recipe to a real life example like cars, planes, electricity usage etc),
  foodWaste: Object(this field is for food waste estimation of the recipe. This field is an object, the keys of the object contains the name of the ingredient whose little portion might go to waste while preparing the recipe and the values of the object contains the waste amount. Follow this format: {"ingredient name": "waste amount (note: amount should be in units like '50 grams' or '1 tablespoon', no explanation)}),
  nutrition: {
    nutrients: [
      {
        name: "Fat",
        amount: Number(amount for 1 person serving),
        unit: "g",
        percentOfDailyNeeds: Number,
      },
      {
        name: "Carbohydrates",
        amount: Number(amount for 1 person serving),
        unit: "g",
        percentOfDailyNeeds: Number,
      },
      {
        name: "Sugar",
        amount: Number(amount for 1 person serving),
        unit: "g",
        percentOfDailyNeeds: Number,
      },
      {
        name: "Protein",
        amount: Number(amount for 1 person serving),
        unit: "g",
        percentOfDailyNeeds: Number,
      },
      {
        name: "Calories",
        amount: Number(amount for 1 person serving),
        unit: "kcal",
        percentOfDailyNeeds: Number,
      },
    ],
  },
}`;

export default function ConvertToRecipes({ backdropHandler }) {
  const [dragActive, setDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setDragActive(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFileUpload([...files]);
    }
  };

  const handleFileUpload = async (files) => {
    const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
    let allValid = true;
    const validatedFiles = files.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (validatedFiles.length !== files.length) {
      allValid = false;
    }

    if (allValid) {
      setErrorMessage("");
      setUploadedFiles(validatedFiles);
      await getCombinedGptResponse(validatedFiles);
    } else {
      setErrorMessage("Only .png, .jpg, and .jpeg files are allowed.");
    }
  };

  const encodeImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Remove the "data:image/..." prefix
      reader.onerror = (error) => reject(error);
    });
  };

  const getCombinedGptResponse = async (files) => {
    setLoading(true);
    try {
      const base64Images = await Promise.all(
        files.map((file) => encodeImageToBase64(file))
      );

      const messages = [
        {
          role: "user",
          content: [
            {
              type: "text",
              text: `Convert the information given in the attached images into the following json format. It should list out all the information and try to add the missing information as accurately as possible. If the image does not contain any recipe related information or an important information is missing from the recipe, then provide response with a json object named error with tells about the problem. If there are multiple recipes attached in the images, then return array containing objects of the recipes. Here is the format: ${convertToRecipesFormat} `,
            },
            ...base64Images.map((base64Image) => ({
              type: "image_url",
              image_url: {
                url: `data:image/jpeg;base64,${base64Image}`,
              },
            })),
          ],
        },
      ];

      const response = await openai.createChatCompletion({
        model: "gpt-4o",
        response_format: { type: "json_object" },
        messages,
      });
      console.log("gpt response", response.data.choices[0].message.content);
      setErrorMessage("");
      setDescription(JSON.parse(response.data.choices[0].message.content));
    } catch (error) {
      console.error("Failed to get response from GPT:", error.message);
      setErrorMessage("Failed to get response from GPT. Please try again.");
    }
    setLoading(false);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    handleFileUpload([...files]);
  };

  const chooseFilesHandler = () => {
    fileInputRef.current.click();
  };

  return (
    <StyledConvertRecipes>
      <div className="heading">
        <h4>Convert Files to Recipes</h4>
        <button className="icon" onClick={backdropHandler}>
          <CloseIcon htmlColor="#F79C16" fontSize="large" />
        </button>
      </div>

      <div className="upload-files">
        <div
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={`upload-box ${dragActive ? "drag-active" : ""}`}
        >
          <CloudUploadOutlinedIcon htmlColor="gray" fontSize="large" />
          <h5>Drop your files here to upload</h5>
          <p>Works with .JPG, .PNG, and .JPEG files</p>
          {uploadedFiles.length > 0 && (
            <div className="uploaded-files">
              {uploadedFiles.map((file, index) => (
                <p key={index} className="uploaded-file">
                  Uploaded File: {file.name}
                </p>
              ))}
            </div>
          )}
          {errorMessage && <p className="error">{errorMessage}</p>}
          <input
            type="file"
            id="file-upload"
            onChange={handleFileChange}
            ref={fileInputRef}
            accept=".png, .jpg, .jpeg"
            multiple
          />
          <LoadingButton
            title={"Choose Files"}
            loading={loading}
            onClick={chooseFilesHandler}
          />
        </div>
      </div>

      {description && <RecipeCard data={description} />}
    </StyledConvertRecipes>
  );
}

const StyledConvertRecipes = styled.div`
  h5 {
    font-weight: 600;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .upload-files {
    display: flex;
    justify-content: center;
  }

  .error {
      color: red;
    }

  .upload-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    border: 2px dashed var(--secondary-light-color);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    background-color: var(--yellow-color);
    transition: background-color 0.2s ease-in-out;
    transition: border 0.2s ease-in-out;
    p {
      margin: 8px 0;
      font-size: 14px;
    }
    .uploaded-file {
      color: green;
    }
  }

  .drag-active {
    background-color: #e5f2fe;
    border-color: #2196f378;
  }

  .secondary-btn {
    padding: 10px 20px;
    border-radius: 50px;
    margin-top: 10px;
    cursor: pointer;
  }

  input[type="file"] {
    display: none;
  }

  .uploaded-files {
    margin-top: 10px;
  }

  .description {
    margin-top: 20px;
    text-align: left;
    width: 100%;
    max-width: 400px;

    h5 {
      margin-bottom: 10px;
    }

    p {
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
`;
