import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../authentication/firebase";

export default function useDatabaseRecipes(recipesType, noOfRecipes) {
  const [fetchedRecipes, setFetchedRecipes] = useState({
    loading: false,
    data: [],
  });
  const [lastDoc, setLastDoc] = useState(null);

  const { diet_preferences, intolerances, cuisine_preferences } = useSelector(
    (state) => state.user.preferences
  );
  // console.log("diet", diet_preferences, intolerances, cuisine_preferences)
  function randomSort(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const fetchRecipes = async (params) => {
    setFetchedRecipes((prevRecipes) => {
      return {
        data: [...prevRecipes.data],
        loading: true,
      };
    });
    try {
      // let q;
      let cuisines = [];
      params.cuisine?.forEach((name) => {
        console.log("name", name);
        cuisines.push(...name.split(","));
      });
      console.log("cusines", params.cuisine, cuisines);
      
      const colRef = collection(db, recipesType);

      // Build the base query with ordering
      let q = query(
        colRef,
        // orderBy("generationDate", "desc"),
        limit(noOfRecipes)
      );

      // Add optional where clause for search input
      // if(params.query?.length > 0) {
      //   q = query(q, where("title", "array-contains-any", cuisines));
      // }

      // Add optional where clause for cuisines (if cuisines exist)
      if (cuisines.length > 0) {
        q = query(q, where("cuisines", "array-contains-any", cuisines));
      }

      if(params.maxReadyTime) {
        q = query(q, where("readyInMinutes", "<=", params.maxReadyTime))
      }

      // Add optional startAfter clause for pagination (if lastDoc exists)
      if (lastDoc) {
        q = query(q, startAfter(lastDoc));
      }

      const docsRef = await getDocs(q);
      let fetchedDocs = [];
      docsRef.forEach((doc) => {
        fetchedDocs.push({ ...doc.data(), id: doc.id });
      });
      console.log("fetched gpt recipes", fetchedDocs);
      setLastDoc(docsRef.docs[docsRef.docs.length - 1]);
      setFetchedRecipes({
        loading: false,
        data:
          params.combineResults === true
            ? [...fetchedRecipes.data, ...fetchedDocs]
            : fetchedDocs,
      });
      return fetchedDocs;
    } catch (error) {
      setFetchedRecipes({ loading: false, data: [] });
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchRecipes({
      number: noOfRecipes,
    });
  }, []);
  return { fetchedRecipes, setFetchedRecipes, fetchRecipes };
}
