import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";

export default function TypewriterBar() {
  const [progress, setProgress] = useState(0);
  const totalDuration = 35; // duration in seconds

  useEffect(() => {
    // start the timer when the component mounts
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        // update the progress by 1/totalDuration every 0.1 second
        const newProgress = oldProgress + 100 / (totalDuration * 10);
        return Math.min(newProgress, 100);
      });
    }, 100); // 0.1 seconds

    // clear the timer when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <StyledBar progress={progress}>
      <Typewriter
        onInit={(typewriter) => {
          typewriter
            .typeString("Generating the recipes!")
            .pauseFor(3000)
            .deleteAll()
            .typeString("Preparing ingredients list!")
            .pauseFor(3000)
            .deleteAll()
            .typeString("Getting nutrition facts!")
            .start();
        }}
      />
      <div className="progress-bar">
        <div />
        {/* <span>{parseInt(progress)}%</span> */}
      </div>
    </StyledBar>
  );
}

const StyledBar = styled.div`
  /* @import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap"); */

  .Typewriter {
    /* font-family: "Source Code Pro", monospace; */
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: var(--accent-color);
    }
  }
  .progress-bar {
    margin-top: 4px;
    border: 2px solid var(--green-color);
    width: 400px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    div {
      width: ${(props) => `${props.progress}%`};
      background: linear-gradient(
        170deg,
        var(--accent-color),
        var(--secondary-dark-color),
        var(--green-color)
      );
      height: 10px;
    }
    span {
      position: absolute;
      right: 5%;
      top: 0;
      font-weight: bold;
      font-style: italic;
    }
  }
  @media screen and (max-width: 600px) {
    .progress-bar {
      width: 300px;
    }
  }
`;
