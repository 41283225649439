import { Delete } from "@mui/icons-material";
import React from "react";
import { useState } from "react";
import styled from "styled-components";

function ShoppingItem({ item, setTotalPrice }) {
  const { image, nameClean, original, amount, unit } = item;

  // const clickHandler = (addValue) => {
  //   if (quantity + addValue !== 0) {
  //     setQuantity((prev) => prev + addValue);
  //     setTotalPrice((prev) => prev + price * addValue);
  //   }
  // };
  return (
    <StyledItem>
      <img
        src={`https://spoonacular.com/cdn/ingredients_100x100/${image}`}
        alt="img"
      />
      <div className="content">
        <h4>{nameClean?.charAt(0).toUpperCase() + nameClean?.slice(1)}</h4>
        <p>{original}</p>
      </div>
      <div className="price">
        <p>{amount}</p>
        <p>{unit}</p>
      </div>
      {/* <div className="buttons">
        <button onClick={(e) => clickHandler(1)}>+</button>
        <h5>{quantity}</h5>
        <button onClick={(e) => clickHandler(-1)}>-</button>
      </div> */}
      {/* <div className="price">
        <h5>${price}</h5>
        <button>
          <Delete fontSize="large" />
        </button>
      </div> */}
    </StyledItem>
  );
}

const StyledItem = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 2.5fr 1fr;
  padding: 10px 0;
  border-bottom: 1px solid var(--shadow);
  gap: 28px;
  width: 90%;
  img {
    background-color: var(--yellow-color);
    border-radius: 12px;
    max-width: 100px;
  }
  .content {
    flex-basis: 60%;
    p {
      max-width: 30ch;
    }
  }
  button {
    background-color: transparent;
    min-width: 50px;
    padding: 10px 0;
    color: var(--text-light-color);
    font-size: 24px;
    &:hover {
      cursor: pointer;
    }
  }
  .buttons,
  .price {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
  .price {
    gap: 20px;
    h5 {
      color: var(--text-color);
      font-weight: 600;
    }
    svg {
      visibility: hidden;
    }
  }
`;
export default ShoppingItem;
